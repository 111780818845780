import Question from 'app/models/question';
import { QuestionnaireUser } from 'app/questionnaire/questionnaire-user';

export default class QuestionnaireState {
  questions: { [key: string]: Question } = {};
  currentFlow: string | null = null;
  completedPages: number[] = [];
  user: QuestionnaireUser = new QuestionnaireUser();
  referringListingId: number = null;
  selectedPlace : string = '';
  selectedLatitude : number;
  selectedLongitude : number;
  addressComponents: google.maps.GeocoderAddressComponent[] = [];
}
