
  <div class="row text-center">
    <div class="col-sm-12">
      <h2 class="heading">Oops!</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 offset-md-3 text-center pt-2">
      <p class="invitation-copy">
        We've found your account, but it looks like your invitation has expired. Please contact us at
        <a textCta="light" href="mailto:{{ 'supportEmail' | branding | async }}">{{ 'supportEmail' | branding | async }}</a> or
        <a textCta="light" href="tel:215-607-8942">(215) 607-8942</a> so we can get you reactivated!
      </p>
      <br>
      <a class="btn btn-primary" [routerLink]="['/login']">Back to Login</a>
    </div>
  </div>
