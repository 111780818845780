import { Pipe, PipeTransform } from '@angular/core';
import { Brand, Brands } from 'app/models/brand';
import { IDENTITIES, Identities } from 'app/models/identity';
import { IdentityService } from 'app/services/identity.service';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'branding'
})
export class BrandingPipe implements PipeTransform {
  constructor(private identityService: IdentityService) { }


  transform(item: keyof Brand, config: { identity?: Brands } = {}) {
    return this.identityService.getCurrentBranding().pipe(
      map(brand => {
        const identity = config.identity || brand;
        return IDENTITIES[identity].branding[item];
    }))

  }
}
