import { Component } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent {
  hostName = environment.hostName;

  constructor() { }
}
