import { Action } from '@ngrx/store';
import { CoverageAreaData } from 'app/services/coverage-area.service';

export enum RemoteCoverageAreaActionTypes {
  REMOTE_COVERAGE_AREA_FETCHED = 'REMOTE_COVERAGE_AREA_FETCHED'
}

export class RemoteCoverageAreaFetchedAction implements Action {
  readonly type = RemoteCoverageAreaActionTypes.REMOTE_COVERAGE_AREA_FETCHED;
  constructor(public remoteCoverageAreaData: CoverageAreaData) { }
}
