
import { createReducer, on } from '@ngrx/store';
import { DocumentType } from 'app/models/document';
import { documentTypesFetched } from '../actions/document-types.actions';

export const initialDocumentTypesState : DocumentType[] = []

// we want whatever came from api in the store for ref data.
// do not care whats currently in store, api is source of truth
export const documentTypesReducer = createReducer(
  initialDocumentTypesState,
  on(documentTypesFetched, (state, { documentTypes }) => documentTypes),
);
