import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'app/services/api.service';
import { AppState } from 'app/store/states/app.state';
import { Store } from '@ngrx/store';
import { UserLoginSuccessAction } from 'app/store/actions/login.actions';
// import { AuthService as OAuthService } from 'angular2-social-login';
import { throwError } from 'rxjs';
import { merge } from 'lodash-es';
import { User } from 'app/models/user';
import { tap, map } from 'rxjs/operators';
import { DashNotificationsService } from 'app/services/dash-notifications.service';
import { CoverageAreaService } from 'app/services/coverage-area.service';
import { TemplateTimelineService } from 'app/services/template-timeline.service';
import { Router } from '@angular/router';
import { UrlService } from './url.service';
import { AirbrakeService } from './airbrake.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private apiService: ApiService,
    private store: Store<AppState>,
    private cookies: CookieService,
    private notifications: DashNotificationsService,
    private coverageAreaService: CoverageAreaService,
    private templateTimelineService: TemplateTimelineService,
    private router: Router,
    private urlService: UrlService,
    private airbrakeService: AirbrakeService,
    // private social: OAuthService,
  ) {}

  login(username: string, password: string, rememberMe: boolean = false) {
    return this.apiService.post('tokens', { username: username, password: password }).pipe(
      tap(response => this.setLoggedInUser(response, rememberMe))
    )
  }

  // socialLogin(provider: string) {
  //   /* You might be asking yourself, why convert to promise and back again?
  //       That's a great question. Using a normal observable with switchMap here broke the loading spinner.
  //   */
  //   return Observable.fromPromise(
  //     this.social.login(provider).toPromise()
  //       .then((data: any) => {
  //         return this.apiService.post('tokens', {username: data.email, provider, token: data.token, name: data.name})
  //           .do((response: any) => {
  //             this.handleLoginSuccess(response);
  //           },
  //           (response: any) => {
  //             this.store.dispatch(userLoginFailure(response));
  //             this.notifications.error('An error occurred while logging in. Please try again.');
  //           }).toPromise();
  //       })
  //   );
  // }

  async logout(url?: string): Promise<void> {
    try {
      // Expect routingExecuted to be true always, if it is not we want to know about it
      const queryParams = url ? { returnUrl: url } : {};
      const routingExecuted = await this.router.navigate(this.urlService.logoutUrl(), { queryParams });

      if (!routingExecuted) {
        throw new Error('A user attempted to logout and was not redirected to the logout component');
      }
    } catch (err) {
      this.airbrakeService.notify({ error: `LOGOUT ERROR: ${err}` });
    }
  }

  forgotPassword(username: string) {
    return this.apiService.post('password', { username });
  }

  resetPassword(newPassword: string, resetToken: string) {
    return this.apiService.patch('password', {password: newPassword, reset_token: resetToken});
  }

  signupUser(newUser: User, invite_token: string, password: string) {
    if (!invite_token) return throwError({message: 'Token not present'});

    return this.apiService.post('users', merge(newUser, { invite_token, password }))
      .pipe(
        tap(response => this.setLoggedInUser(response)),
        tap(
          () => this.notifications.success(`Account updated successfully!`),
          (response: any) => this.notifications.error((response && response.message) ? response.message : 'An unknown error occurred while trying to update your account. Please reach out to support@newfoundgroup.com for assistance!'),
        ),
      );
  }

  isLoggedIn() {
    return this.store
      .select(state => state.currentUser)
      .pipe(map(currentUser => !!currentUser));
  }

  setLoggedInUser(response: any, rememberMe: boolean = false){

    // Note: It's important to set the authToken first here before the
    // current user incase anything is subscribing to the current user
    // assuming that's the signal everything is ready (including the authToken)
    let cookieExpiration = rememberMe ? 7 : null;
    this.cookies.set('authToken', response.token, cookieExpiration, '/')

    // kmiscia 1/29/20 - This gives us a blunt but effective way to clear out users' local storage.
    // This ability is useful when we've made either large or small changes to the schema of
    // items stored in local storage, or the schema of local storage itself. In the past, we've attempted
    // to 'migrate' users' localstorage to the correct format, but that is usually pretty
    // complicated and has minimal return value given the nature of things we're currently storing (cached values
    // of models, search filter preferences, etc). Using this feature usually involves setting this flag in the DB
    // for all or a subset of users, and then clearing out the auth tokens for those users to force them to login again
    // and hit this code. Future enhancements may include changing the flag from a boolean to something that allows us to
    // specify on those parts of localstorage we'd like to clear, or possbilty divide local storage into things we can safely
    // clear fairly often (like cached models) and things we should ideally try to migrate (like saved user settings)
    if(response.clear_local_data){
      window.localStorage.clear();
    }

    this.store.dispatch(new UserLoginSuccessAction(response));
    this.coverageAreaService.fetchCoverageAreas().subscribe();
    this.templateTimelineService.getTemplates().subscribe();

    this.goToLoginLandingPage(response);
  }

  goToLoginLandingPage(response: any){

    if(response.dotloop_state_token && response.dotloop_state_token.length) {
      this.router.navigate([ 'dash', 'dotloop-authorization', 'authorize' ], { queryParams: { state: response.dotloop_state_token }});
      return;
    }

    if(response.user.welcomed){
      this.router.navigate([ 'dash' ]);
    } else {
      this.router.navigate([ 'dash', 'welcome' ], { queryParams: { returnUrl: 'dash/home' }});
    }
  }
}

