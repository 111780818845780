import { Action } from '@ngrx/store';
import UserSettings from 'app/models/user-setttings';

export enum UserSettingsActionTypes {
  USER_SETTINGS_UPDATED = 'app/shared/user_settings/UPDATED',
}

export class UserSettingsUpdatedAction implements Action {
  readonly type = UserSettingsActionTypes.USER_SETTINGS_UPDATED;
  constructor(public userSettings: UserSettings) { }
};