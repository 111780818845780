import { Action } from '@ngrx/store';
import { User } from 'app/models/user';

export enum UserActionTypes {
  USER_UPDATED = 'app/shared/user/UPDATED',
  CURRENT_USER_UPDATED = 'app/shared/user/CURRENT_UPDATED',
  USER_DELETED = 'app/shared/user/DELETED',
  USER_FETCHED = 'app/shared/user/FETCHED',
  CLEAR_USERS = 'app/shared/user/CLEAR_USERS'
}

export class UserUpdatedAction implements Action {
  readonly type = UserActionTypes.USER_UPDATED;
  constructor(public user: User) { }
};

export class UserDeletedAction implements Action {
  readonly type = UserActionTypes.USER_DELETED;
  constructor(public user: User) { }
};

export class UserFetchedAction implements Action {
  readonly type = UserActionTypes.USER_FETCHED;
  constructor(public user: User) { }
};

export type UserActions = UserUpdatedAction | UserDeletedAction | UserFetchedAction
