import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { SupportedRequestMethod } from "app/models/supported-request-method";
import { Store } from 'app/store/states/app.state';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { AddLoadingTimeoutAction, RemoveLoadingTimeoutAction, ShowLoadingTimeoutAction } from 'app/store/actions/api.action';
import { UserLogoutAction } from 'app/store/actions/logout.actions';

import { Notifier } from '@airbrake/browser';

import { tap, map, catchError, finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  airbrake: Notifier;

  constructor(
    private http: HttpClient,
    private store: Store<any>,
    private cookies: CookieService,
  ) {
    if (environment.airbrakeProjectId && environment.airbrakeProjectKey) {
      this.airbrake = new Notifier({
        projectId: environment.airbrakeProjectId,
        projectKey: environment.airbrakeProjectKey
      });
    }
  }

  static apiUrlWithRoot(url: string) {
    return environment.apiRoot + '/' + url + '.json';
  }

  static authorizationHeaderName() {
    return 'Authorization';
  }

  static authorizationHeaderValue(authToken: string) {
    return `Bearer ${authToken}`;
  }

  private withRequestOptions<T>(callback: (options: Object) => Observable<T>, requestMethod: SupportedRequestMethod, params?: { [key: string]: any }) {

    let authorizationHeader: any = {};
    if (this.cookies.get('authToken') && this.cookies.get('authToken').length) {
      authorizationHeader[ApiService.authorizationHeaderName()] = ApiService.authorizationHeaderValue(this.cookies.get('authToken'));
    }

    let headers = new HttpHeaders(authorizationHeader);
    headers.append('Access-Control-Request-Method', requestMethod as string);
    headers.append('Access-Control-Request-Headers', 'X-Requested-With');
    headers.append('ngsw-bypass', '');

    let httpParams = new HttpParams({ fromObject: params });
    return callback({ headers, params: httpParams, withCredentials: true });
  }

  private httpRequestWrapper<T>(response: Observable<HttpResponse<Object>>, showLoadingIndicator = true): Observable<T> {

    let loadingTimeout: number;

    if (showLoadingIndicator) {
      loadingTimeout = this.createLoadingTimeout();
      this.store.dispatch(new AddLoadingTimeoutAction(loadingTimeout));
    }

    return response
      .pipe(
        map<HttpResponse<Object>, any>((res: HttpResponse<Object>, index: number) => {
          return res
        }),
        catchError((err: HttpErrorResponse) => {

          if (err.status == 401) this.endSession();

          let parsedError;

          // Not sure why this try/catch is needed (ie. under what circumstance an HttpErrorResponse)
          // would blow up having it's error property accessed. Leaving it for now but can hopefully be
          // removed at a later date when we're comforatable.
          try {
            parsedError = err.error;
          } catch (e) {
            parsedError = {
              status: (err && err.status) ? err.status : null,
              message: "An unknown error occurred. Please contact support@newfoundgroup.com for assistance."
            };
          }

          throw parsedError;
        }),
        finalize(() => this.store.dispatch(new RemoveLoadingTimeoutAction(loadingTimeout)))
      )
  }

  get<T>(url: string, { params = {}, showLoadingIndicator = true }: ApiRequestOptions = {}): Observable<T> {
    return this.withRequestOptions(options => {
      return this.httpRequestWrapper(
        this.http.get<HttpResponse<Object>>(ApiService.apiUrlWithRoot(url), options),
        showLoadingIndicator,
      );
    }, SupportedRequestMethod.GET, params);
  }

  post<T>(url: string, body?: any, { showLoadingIndicator = false } = {}): Observable<T> {
    return this.withRequestOptions(options => {
      return this.httpRequestWrapper(
        this.http.post<HttpResponse<Object>>(ApiService.apiUrlWithRoot(url), body, options),
        showLoadingIndicator,
      );
    }, SupportedRequestMethod.POST)
  }

  patch<T>(url: string, body?: any, { params = {}, showLoadingIndicator = true }: ApiRequestOptions = {}): Observable<T> {
    return this.withRequestOptions(options => {
      return this.httpRequestWrapper(
        this.http.patch<HttpResponse<Object>>(ApiService.apiUrlWithRoot(url), body, options),
        showLoadingIndicator,
      );
    }, SupportedRequestMethod.PATCH, params)
  }

  delete<T>(url: string, { params = {}, showLoadingIndicator = true }: ApiRequestOptions = {}): Observable<T> {
    return this.withRequestOptions(options => {
      return this.httpRequestWrapper(
        this.http.delete<HttpResponse<Object>>(ApiService.apiUrlWithRoot(url), options),
        showLoadingIndicator,
      );
    }, SupportedRequestMethod.DELETE, params)
  }

  endSession() {
    this.cookies.delete('authToken', '/');
    this.store.dispatch(new UserLogoutAction());
  }

  private createLoadingTimeout(): number {
    let loadingTimeout = window.setTimeout(() => {
      this.store.dispatch(new ShowLoadingTimeoutAction(loadingTimeout));
    }, 1000);

    return loadingTimeout;
  }
}

interface ApiRequestOptions {
  params?: Object;
  showLoadingIndicator?: boolean;
}
