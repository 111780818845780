import { Action } from '@ngrx/store';
import Question from 'app/models/question';

export enum QuestionnaireActionTypes {
  QUESTION_FETCH_COMPLETE = 'QUESTION_FETCH_COMPLETE',
  QUESTION_OPTION_CHANGED = 'QUESTION_OPTION_CHANGED',
  QUSER_ATTR_CHANGED = 'QUSER_ATTR_CHANGED',
  SELL_FUNNEL_INITIATED = 'SELL_FUNNEL_INITIATED',
  BUY_FUNNEL_INITIATED = 'BUY_FUNNEL_INITIATED',
  BOTH_FUNNEL_INITIATED = 'BOTH_FUNNEL_INITIATED',
  MORTGAGE_FUNNEL_INITIATED = 'MORTGAGE_FUNNEL_INITIATED',
  REFINANCE_FUNNEL_INITIATED = 'REFINANCE_FUNNEL_INITIATED',
  INQUIRY_FUNNEL_INITIATED = 'INQUIRY_FUNNEL_INITIATED',
  PAGE_COMPLETED = 'QUESTIONNAIRE_STEP_COMPLETED',
  SURVEY_COMPLETED = 'SURVEY_COMPLETE',
  SURVEY_RESTARTED = 'SURVEY_RESTARTED',
  REFERRING_TIMELINE = 'REFERRING_TIMELINE',
  SELECTED_PLACE = 'SELECTED_PLACE',
  ADDRESS_COMPONENTS = 'ADDRESS_COMPONENTS',
  SELECTED_STATE = 'SELECTED_STATE',
  SELECTED_COORDINATES = 'SELECTED_COORDINATES',
}

export class QuestionFetchCompletedAction implements Action {
  readonly type = QuestionnaireActionTypes.QUESTION_FETCH_COMPLETE;
  constructor(public questions: Question[]) { }
}

export class QuestionOptionsChangedAction implements Action {
  readonly type = QuestionnaireActionTypes.QUESTION_OPTION_CHANGED;
  constructor(public name: string, public newValue: string) { }
}

export class QuestionnaireUserAttrChangedAction implements Action {
  readonly type = QuestionnaireActionTypes.QUSER_ATTR_CHANGED;
  constructor(public name: string, public newValue: string|boolean) { }
}

export class QuestionnairePageCompletedAction implements Action {
  readonly type = QuestionnaireActionTypes.PAGE_COMPLETED;
  constructor(public stepNumber: number) { }
}

export class QuestionnaireSellFunnelInitiatedAction implements Action {
  readonly type = QuestionnaireActionTypes.SELL_FUNNEL_INITIATED;
}

export class QuestionnaireBuyFunnelInitiatedAction implements Action {
  readonly type = QuestionnaireActionTypes.BUY_FUNNEL_INITIATED;
}

export class QuestionnaireBothFunnelInitiatedAction implements Action {
  readonly type = QuestionnaireActionTypes.BOTH_FUNNEL_INITIATED;
}

export class QuestionnaireMortgageFunnelInitiatedAction implements Action {
  readonly type = QuestionnaireActionTypes.MORTGAGE_FUNNEL_INITIATED;
}

export class QuestionnaireRefinanceFunnelInitiatedAction implements Action {
  readonly type = QuestionnaireActionTypes.REFINANCE_FUNNEL_INITIATED;
}

export class QuestionnaireInquiryFunnelInitiatedAction implements Action {
  readonly type = QuestionnaireActionTypes.INQUIRY_FUNNEL_INITIATED;
}

export class QuestionnaireSurveyCompletedAction implements Action {
  readonly type = QuestionnaireActionTypes.SURVEY_COMPLETED;
}

export class QuestionnaireSurveyRestartedAction implements Action {
  readonly type = QuestionnaireActionTypes.SURVEY_RESTARTED;
}

export class ReferringTimelineAction implements Action {
  readonly type = QuestionnaireActionTypes.REFERRING_TIMELINE;
  constructor(public referringListingId: number | string) { }
}

export class PlaceAddressComponentsAction implements Action {
  readonly type = QuestionnaireActionTypes.ADDRESS_COMPONENTS;
  constructor(public placeAddressComponents: google.maps.GeocoderAddressComponent[]) { }
}
export class SelectedPlaceAction implements Action {
  readonly type = QuestionnaireActionTypes.SELECTED_PLACE;
  constructor(public selectedPlace: string) { }
}

export class SelectedCoordinatesAction implements Action {
  readonly type = QuestionnaireActionTypes.SELECTED_COORDINATES;
  constructor(public selectedLatitude: number, public selectedLongitude: number) { }
}

export type QuestionnaireActions = QuestionnaireSellFunnelInitiatedAction | QuestionnaireBuyFunnelInitiatedAction | QuestionnaireBothFunnelInitiatedAction | QuestionnaireSurveyCompletedAction | QuestionnaireSurveyRestartedAction | QuestionFetchCompletedAction | QuestionOptionsChangedAction | QuestionnaireUserAttrChangedAction | QuestionnairePageCompletedAction | ReferringTimelineAction | QuestionnaireMortgageFunnelInitiatedAction | QuestionnaireRefinanceFunnelInitiatedAction | QuestionnaireInquiryFunnelInitiatedAction | PlaceAddressComponentsAction | SelectedPlaceAction | SelectedCoordinatesAction;





