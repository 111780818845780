import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  constructor(private router: Router, private auth: AuthService) { }

  ngOnInit() {

    this.auth.isLoggedIn().pipe(first()).subscribe(isLoggedIn => {
      if (!isLoggedIn) {
        this.router.navigate(['login']);
      }
    });
  }

}
