import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { PipesModule } from 'app/modules/pipes/pipes.module';
import { NewSharedModule } from 'app/new_shared/new-shared.module';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    AuthLayoutComponent
  ],
  imports: [
    RouterModule,
    NewSharedModule,
    PipesModule,
    CommonModule,
  ],
  exports:[
    AuthLayoutComponent
  ]
})
export class AuthLayoutModule { }
