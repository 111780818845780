import { Assets } from "./asset";
import { Brand, Brands } from "./brand";

export interface Identity {
  branding: Brand;
  assets: Assets,
}

export interface Identities {
  houwzer: Identity;
  newfound: Identity;
  trelora: Identity;
}

export const IDENTITIES: Identities = {
  [Brands.houwzer]: {
    branding: {
      name: 'Houwzer',
      supportEmail: 'support@newfoundgroup.com',
      successEmail: 'success@houwzer.com',
    },
    assets: {
      logo: {
        light: 'assets/houwzer/houwzer-logo-light.png',
        dark: 'assets/houwzer/houwzer-logo-dark.png',
        alt: 'houwzer logo'
      },
      icon: {
        light: 'assets/houwzer/houwzer-symbol-light.png',
        dark: 'assets/houwzer/houwzer-symbol-dark.png',
        alt: 'houwzer symbol'
      },
    }
  },
  [Brands.newfound]: {
    branding: {
      name: 'Dash',
      supportEmail: 'support@newfoundgroup.com',
      successEmail: 'success@newfoundgroup.com',
    },
    assets: {
      logo: {
        light: 'assets/newfound/dash-logo-light.png',
        dark: 'assets/newfound/dash-logo-dark.png',
        alt: 'dash logo'
      },
      icon: {
        light: 'assets/newfound/newfound-symbol-light.png',
        dark: 'assets/newfound/newfound-symbol-dark.png',
        alt: 'dash symbol'
      },
    }
  },
  [Brands.trelora]: {
    branding: {
      name: 'Trelora',
      supportEmail: 'support@newfoundgroup.com',
      successEmail: 'success@newfoundgroup.com',
    },
    assets: {
      logo: {
        light: 'assets/trelora/trelora-logo-light.png',
        dark: 'assets/trelora/trelora-logo-dark.png',
        alt: 'trelora logo'
      },
      icon: {
        light: 'assets/trelora/trelora-symbol-light.svg',
        dark: 'assets/trelora/trelora-symbol-dark.svg',
        alt: 'trelora symbol'
      },
    }
  }
}
