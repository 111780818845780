import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { formatDate } from 'app/services/date-time-format.service';

@Pipe({ name: 'dashDate' })
export class DashDatePipe implements PipeTransform {

  constructor() {}

  transform(date: Date | string): Observable<string> {
    // Returning an observable here to keep things consistent with how dashDateTime pipe
    // works. Pick your poison really but I'd rather be consistent and predictable
    return of(formatDate(date, 'Etc/UTC'));
  }
}
