
import { createReducer, on } from '@ngrx/store';
import { mlsesFetched } from '../actions/mls-actions.actions';
import { Mls } from 'app/models/mls';

export const initalMlsesState: Mls[] = []

// we want whatever came from api in the store for ref data.
// do not care whats currently in store, api is source of truth
export const mlsesReducer = createReducer(
  initalMlsesState,
  on(mlsesFetched, (state, { mlses }) => mlses),
);
