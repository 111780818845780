  <div class="row text-center">
    <div class="col-sm-12">
      <h2 class="heading">Reset Your Password</h2>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-md-6 offset-md-3">
      <form #resetPasswordForm="ngForm" class="reset-password-form" (submit)="onSubmit()">
        <div>
          <label for="password" class="form-control-label">New Password</label>
          <input
            #passwordInput="ngModel"
            required
            type="password"
            class="form-control"
            [class.is-invalid]="passwordInput.touched && password.length === 0"
            [(ngModel)]="password"
            name="password"
          >
        </div>
        <div class="mt-3">
          <label for="password" class="form-control-label">Confirm New Password</label>
          <input
            #confirmationInput="ngModel"
            required
            type="password"
            class="form-control"
            [(ngModel)]="passwordConfirmation"
            [class.is-invalid]="confirmationInput.touched && passwordConfirmation.length > 0 && passwordConfirmation !== password"
            name="password"
          >
          <div [hidden]="confirmationInput.pristine || password === passwordConfirmation" class="form-control-feedback">Password and Confirmation Must Match</div>
        </div>
        <div class="d-flex justify-content-center mt-3">
          <input class="btn btn-primary" type="submit" value="Submit" [disabled]="submitDisabled()">
        </div>
      </form>
    </div>
  </div>
