import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'brands-showcase',
  template: `
    <div class="images" [class.stack]="stack">
      <div class="image-wrapper">
        <img src="assets/houwzer/houwzer-logo-{{tone}}.png" alt="App logo" class="image" />
      </div>
      <div class="image-wrapper">
        <img src="assets/newfound/dash-logo-{{tone}}.png" alt="App logo" class="image" />
      </div>
      <div class="image-wrapper">
        <img src="assets/trelora/trelora-logo-{{tone}}.png" alt="App logo" class="image" />
      </div>
    </div>
  `,
  styleUrls: ['./brands-showcase.component.scss']
})
export class BrandsShowcaseComponent implements OnInit {
  @Input() tone: 'light' | 'dark' = 'dark';
  @Input() stack = false;

  constructor() { }

  ngOnInit(): void {
  }

}
