import { Component, OnInit, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import Invitation from 'app/models/invitation';
import { UserManagementUser } from 'app/models/user-management-user';
import { includes } from 'lodash-es';
import { AuthService } from 'app/services/auth.service';
import { DashNotificationsService } from 'app/services/dash-notifications.service';
import { Roles } from 'app/models/role';
import { User } from 'app/models/user';


@Component({
  selector: 'signup-form',
  templateUrl: './signup-form.component.html',
  styleUrls: ['./signup-form.component.scss']
})
export class SignupFormComponent implements OnInit {

  @ViewChild('signupForm', { static: true }) signupForm: NgForm;

  @Output('onSubmit') onSubmit = new EventEmitter<any>();

  @Input('invitation') invitation: Invitation;
  @Input('token') token: string;

  user: User;

  isInternalUser: boolean = false;
  isAgent: boolean = false;

  password = '';
  passwordConfirmation = '';

  constructor(
    private service: AuthService,
    private notifications: DashNotificationsService,
  ) {}

  ngOnInit() {
    this.isInternalUser = this.invitation.user.role.slug !== Roles.client
    this.isAgent = includes([Roles.buyerAgent, Roles.listingAgent], this.invitation.user.role.slug);
    if(this.invitation.user) this.user = this.invitation.user;
  }

  submit() {
    this.service.signupUser(this.user, this.token, this.password)
      .subscribe(
        (success: any) => {
          if(this.onSubmit) {
            let initialTimeline = success.timelines[0];
            this.onSubmit.emit(initialTimeline);
          }
        },
        (err: any) => {
          if(err && err.message) {
            this.notifications.error(err.message);
          } else {
            this.notifications.error('It looks like something went wrong. Please reach out to support@newfoundgroup.com and we\'ll get things back on track!');
          }

        }
      );
  }

  submitDisabled() {
    if(!this.signupForm) return true;

    if(this.isConfirmedUser()){
      return this.signupForm.invalid
    } else {
      return this.signupForm.invalid || this.password !== this.passwordConfirmation;
    }
  }

  isConfirmedUser(){
    return this.invitation && this.user && this.user.id;
  }

}
