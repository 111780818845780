import { Injectable } from '@angular/core';
import { BasicUser } from 'app/models/basic-user';
import { isSeller } from 'app/models/timeline';
import { environment } from 'environments/environment';
import { SavedReport, SavedReportTypes } from 'app/models/saved-report';
import { ActionItem } from 'app/models/action-item';
import { Contactable } from 'app/models/contactable';
import { BasicForm } from 'app/models/basic-form';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, startWith, tap } from 'rxjs/operators';
import { BasicTimeline } from 'app/models/basic-timeline';

@Injectable({
  providedIn: 'root'
})
export class UrlService {
  constructor(private router: Router) { }

  loginUrl() {
    return ['/login']
  }

  logoutUrl() {
    return ['/logout']
  }

  timelineUrl(timeline: any, phaseSequenceNumber?: number): string[] {
    const timelineType = isSeller(timeline) ? 'listings' : 'home-searches';
    const path = ['/dash', timelineType, String(timeline.id)];

    if (phaseSequenceNumber) {
      return [...path, String(phaseSequenceNumber)];
    }

    return path;
  }

  timelineURLWithHost(timeline: any): string {
    return environment.appRoot + this.timelineUrl(timeline).join('/');
  }

  editTimelineUrl(timeline: any, selectedTab?: string) {
    if (!selectedTab) {
      return this.timelineUrl(timeline).concat('edit')
    }

    return this.timelineUrl(timeline).concat('edit', selectedTab)
  }

  editUserUrl(user: BasicUser | Contactable, selectedTab?: string) {
    const url = '/dash/users/edit/' + user.id;

    if (selectedTab) {
      return url + '/' + selectedTab;
    }

    return url;
  }

  hubspotDealUrl(deal_id: number) {
    return `${environment.hubspotBaseUrl}/contacts/${environment.hubspotPortalId}/deal/${deal_id}`;
  }

  savedReportUrl(savedReport: SavedReport) {

    let savedReportUrl = environment.appRoot;

    if (savedReport.report_type === SavedReportTypes.BUYER) {
      savedReportUrl += '/dash/buyer-reporting';
    }

    if (savedReport.report_type === SavedReportTypes.LISTING) {
      savedReportUrl += '/dash/listing-reporting';
    }

    savedReportUrl += `?saved_report_id=${savedReport.id}`;

    return savedReportUrl;
  }

  reportingUrl(userType: string) {
    return `/dash/${userType}-reporting`;
  }

  actionItemsUrl() {
    return '/dash/action-items/';
  }

  actionItemUrl(actionItem: ActionItem) {
    return `/dash/action-items/${actionItem.id}`;
  }

  actionItemReportingUrl() {
    return '/dash/action-items/reporting';
  }

  formUrl(form: BasicForm) {
    return `/dash/forms/${form.id}`
  }

  messagesUrl() {
    return `/dash/messages`;
  }

  // the below usage of the url as a string is to get around the complexity of the angular routes,
  // there isnt an easy way to get the full route via the router/route api, even angular docs suggest a recursive solution
  // https://angular.io/api/router/ActivatedRouteSnapshot#constructor
  // we can work with a string for now, but if this starts to become underpowered, we should consider implementing
  // a general route/router normalizing sevice
  currentUrl() {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.router.url),
      startWith(this.router.url),
    )
  }

  fullUrl() {
    return window.location.href;
  }

  offerUrl({ timeline, offerId }: { timeline: any, offerId: number }){
    return [...this.timelineUrl(timeline), 'offers', String(offerId)];
  }

  isExternalHouwzerRoute(url: string) {
    return [
      url.match('our-homes'),
      url.match('questionnaire')
    ].some(Boolean)
  }

  isTimelinePage(url: string) {
    return [
      url.match(/home-searches\/\d{1,}/),
      url.match(/listings\/\d{1,}/),
    ].some(Boolean)
  }

}
