import TemplateSubscription from 'app/models/template-subscription';

export enum TemplateSubscriptionActionTypes {
  TEMPLATE_SUBSCRIPTIONS_FETCHED = 'template_subscriptions/FETCHED',
  TEMPLATE_SUBSCRIPTION_CREATED = 'template_subscriptions/CREATED',
  TEMPLATE_SUBSCRIPTION_DELETED = 'template_subscriptions/DELETED',
  TEMPLATE_SUBSCRIPTION_UPDATED = 'template_subscriptions/UPDATED'
}

export class TemplateSubscriptionsFetchedAction {
  readonly type = TemplateSubscriptionActionTypes.TEMPLATE_SUBSCRIPTIONS_FETCHED;
  constructor(public templateSubscriptions: TemplateSubscription[]) { }
};

export class TemplateSubscriptionsCreatedAction {
  readonly type = TemplateSubscriptionActionTypes.TEMPLATE_SUBSCRIPTION_CREATED;
  constructor(public templateSubscription: TemplateSubscription) { }
};

export class TemplateSubscriptionsDeletedAction {
  readonly type = TemplateSubscriptionActionTypes.TEMPLATE_SUBSCRIPTION_DELETED;
  constructor(public templateSubscription: TemplateSubscription) { }
};

export class TemplateSubscriptionsUpdatedAction {
  readonly type = TemplateSubscriptionActionTypes.TEMPLATE_SUBSCRIPTION_UPDATED;
  constructor(public templateSubscription: TemplateSubscription) { }
};

export type TemplateSubscriptionActions = TemplateSubscriptionsFetchedAction | TemplateSubscriptionsCreatedAction | TemplateSubscriptionsDeletedAction | TemplateSubscriptionsUpdatedAction;
