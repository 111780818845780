import { User } from 'app/models/user';

export enum UserManagementActionTypes {
  SEARCH_RESULTS_FETCHED = 'admin/users/search/FETCHED',
  EDIT_USER = 'admin/users/EDIT'
}

export class UserSearchResultsFetchedAction {
  readonly type = UserManagementActionTypes.SEARCH_RESULTS_FETCHED;
  constructor(public results: User[]) { }
};

export type UserManagementActions = UserSearchResultsFetchedAction
