import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { ExtraOptions, RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppState, reducers, metaReducers } from 'app/store/states/app.state';
import { authenticationRoutes } from 'app/auth/auth.routes';
import { routes } from './app.routes';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { StoreModule, USER_PROVIDED_META_REDUCERS } from '@ngrx/store';
import { StoreRouterConnectingModule, FullRouterStateSerializer, RouterState } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { ErrorHandler } from '@angular/core';
import { AuthModule } from 'app/auth/auth.module';
import { CookieService } from 'ngx-cookie-service';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { AirbrakeErrorHandler } from 'app/shared/airbrake-error-handler';
import { AppNotificationsComponent } from './app-notifications.component';

const routingConfiguration: ExtraOptions = {
    paramsInheritanceStrategy: 'always'
};


export function getMetaReducers() {
  return metaReducers;
}

export function getReducers() {
  return reducers;
}

export function getInitialState() {
  return new AppState();
}

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<AppState>>('Registered Reducers');
export const INITIAL_STATE = new InjectionToken<ActionReducerMap<AppState>>('Initial State');

@NgModule({
  declarations: [
    AppComponent,
    AppNotificationsComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SimpleNotificationsModule.forRoot(), // NOTE: This must be in app module per docs
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.enableServiceWorkers }),
    StoreModule.forRoot(REDUCER_TOKEN, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      } //immutabilty requires a DEEP COPY of state, which is a lot to implement for something like a timeline object
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 10,
      logOnly: environment.production,
    }), // Note that you must instrument after importing StoreModule
    StoreRouterConnectingModule.forRoot({
      serializer: FullRouterStateSerializer,
      routerState: RouterState.Minimal,
    }),
    RouterModule.forRoot(authenticationRoutes, {}),
    RouterModule.forRoot(routes, routingConfiguration),
    AuthModule,
    FormsModule,
    HttpClientJsonpModule
  ],
  providers: [
    CookieService,
    { provide: ErrorHandler, useClass: AirbrakeErrorHandler },
    { provide: INITIAL_STATE, useFactory: getInitialState},
    { provide: REDUCER_TOKEN, useFactory: getReducers},
    { provide: USER_PROVIDED_META_REDUCERS, useFactory: getMetaReducers},
    { provide: Window, useValue: window }
  ],
  exports: [
    RouterModule,
    SimpleNotificationsModule
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
