import { Component, OnInit } from '@angular/core';
import { User } from 'app/models/user';
import { ActivatedRoute, Router } from '@angular/router';
import Invitation from 'app/models/invitation';
import { InvitationsService } from 'app/services/invitation.service';

@Component({
  selector: 'signup-page',
  templateUrl: './signup-page.component.html',
  styleUrls: ['./signup-page.component.scss']
})
export class SignupPageComponent implements OnInit {

  invitation: Invitation;
  token: string;
  user = new User();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private invitationsService: InvitationsService
  ) { }

  ngOnInit() {

    this.route.queryParams.subscribe((queryParam: any) => {

      this.token = queryParam['token'];

      this.invitationsService.getInvitation(this.token)
        .subscribe((invitation: Invitation) => {

          this.invitation = invitation;

          if (invitation && invitation.accepted_at) {
            this.router.navigate(['signup', 'accepted_invitation'])
            return;
          }

          if (this.invitation && invitation.expired) {
            this.router.navigate(['signup', 'expired_invitation'])
            return;
          }

          if (!this.invitation) {
            this.router.navigate(['signup', 'invalid_invitation'])
            return;
          }
        },
          () => { this.router.navigate(['signup', 'invalid_invitation']) }
        )

    });
  }

  isConfirmedUser() {
    return this.invitation && this.user && this.user.id;
  }
}
