  <div class="row text-center">
    <div class="col-md-6 offset-md-3">
      <h3 class="heading">Whoops!</h3>
      <hr>
      <p class="invitation-copy">
        It looks like that invitation has either expired or is invalid! If you have any questions or would like to renew your invitation,
        do not hesitate to reach out to us at <a textCta="light" href="mailto:{{ 'supportEmail' | branding | async }}">{{ 'supportEmail' | branding | async }}</a>
        or <a textCta="light" href="tel:215-607-8942">(215) 607-8942</a>
      </p>
    </div>
  </div>
