import { combineReducers, Action } from '@ngrx/store';
import { timelinesReducer, sidebarTimelinesResultsReducer } from 'app/store/reducers/timeline.reducers';
import { TimelineActionTypes } from 'app/store/actions/timeline.actions';
import { UserLoginActionTypes } from 'app/store/actions/login.actions';
import { usersReducer } from 'app/store/reducers/user-management.reducers';
import { backgroundImageReducer } from 'app/store/reducers/background-image.reducer';

export const dashboardReducer = combineReducers({
  timelines: timelinesReducer,
  sidebarTimelinesResults: sidebarTimelinesResultsReducer,
  lastRefresh: refreshReducer,
  users: usersReducer,
  backgroundImage: backgroundImageReducer
});

function refreshReducer(state: Date, action: Action) {
  switch (action.type) {
    case TimelineActionTypes.TIMELINES_FETCHED:
    case UserLoginActionTypes.USER_LOGIN_SUCCESS:
      return new Date().getTime();
    default:
      return state;
  }
}
