import { Pipe, PipeTransform } from '@angular/core';
import { formatDateTime } from 'app/services/date-time-format.service';
import { getCurrentUserTimeZone } from 'app/store/selectors/user.selectors';
import { AppState, Store } from 'app/store/states/app.state';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({ name: 'dashDateTime' })
export class DashDateTimePipe implements PipeTransform {

  constructor(private store: Store<AppState>) {}

  transform(date: Date | string): Observable<string> {
    return getCurrentUserTimeZone(this.store).pipe(
      map(timeZone => formatDateTime(date, timeZone))
    )
  }
}

