import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { getCurrentUserTimeZone } from 'app/store/selectors/user.selectors';
import { AppState, Store } from 'app/store/states/app.state';
import { map } from 'rxjs/operators';
import { formatTime } from 'app/services/date-time-format.service';

@Pipe({ name: 'dashTime' })
export class DashTimePipe implements PipeTransform {

  constructor(private store: Store<AppState>) {}

  transform(date: Date | string): Observable<string> {
    return getCurrentUserTimeZone(this.store).pipe(
      map(timeZone => formatTime(date, timeZone))
    )
  }
}
