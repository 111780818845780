import { ActionReducer } from '@ngrx/store';
import { ActionItem } from 'app/models/action-item';
import { ActionItemActions, ActionItemActionTypes } from 'app/store/actions/action-item.actions';
import { findIndex, remove } from 'lodash-es';
import { UserLogoutActions, UserLogoutActionTypes } from '../actions/logout.actions';

export const actionItemsReducer: ActionReducer<ActionItem[]> = (state: ActionItem[], action: ActionItemActions | UserLogoutActions) => {
  switch (action.type) {
    case ActionItemActionTypes.ACTION_ITEM_RECEIVED:
      return updateOrCreateActionItem(action.actionItem, state);
    case ActionItemActionTypes.ACTION_ITEM_DELETED:
      return deleteActionItem(action.actionItemId, state);
    case ActionItemActionTypes.ACTION_ITEMS_FETCHED:
      state = action.actionItems;
      return state.slice(0);
    case UserLogoutActionTypes.USER_LOG_OUT:
      return [];
    default:
      return state;
  }
};

function deleteActionItem(actionItemId: number, state: ActionItem[]){
  const newState = state.slice(0);
  remove(newState, (existingActionItem: ActionItem) => existingActionItem.id === actionItemId);
  return newState;
}

function updateOrCreateActionItem(actionItem: ActionItem, state: ActionItem[]){
  const index = findIndex(state, ai => ai.id === actionItem.id);
  return (index >= 0) ? updateActionItem(actionItem, state) : addActionItem(actionItem, state);
}

function addActionItem(actionItem: ActionItem, state: ActionItem[]){
  const newState = [].concat(state);
  newState.push(actionItem);
  return newState;
}

function updateActionItem(actionItem: ActionItem, state: ActionItem[]){
  const index = findIndex(state, ai => ai.id === actionItem.id);
  const newState = [].concat(state);
  if (index >= 0) {
    newState.splice(index, 1, actionItem);
  }
  return newState;
}
