import { Action } from '@ngrx/store';
import { ActionItem } from 'app/models/action-item';

export enum ActionItemActionTypes {
  ACTION_ITEMS_FETCHED = 'action_items/FETCHED',
  ACTION_ITEM_DELETED = 'action_item/DELETED',
  ACTION_ITEM_RECEIVED = 'action_item/RECEIVED',
}

export class ActionItemsFetchedAction implements Action {
  readonly type = ActionItemActionTypes.ACTION_ITEMS_FETCHED;
  constructor(public actionItems: ActionItem[]) { }
};

export class ActionItemDeletedAction implements Action {
  readonly type = ActionItemActionTypes.ACTION_ITEM_DELETED;
  constructor(public actionItemId: number) { }
};

export class ActionItemReceivedAction implements Action {
  readonly type = ActionItemActionTypes.ACTION_ITEM_RECEIVED;
  constructor(public actionItem: ActionItem) { }
};

export type ActionItemActions = ActionItemsFetchedAction | ActionItemReceivedAction | ActionItemDeletedAction
