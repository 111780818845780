import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IconModule } from 'app/modules/icon/icon.module';
import { ShareComponent } from 'app/shared/components/share/share.component';
import { SpinnerComponent } from 'app/shared/components/spinner/spinner.component';
import { ThrottleClickDirective } from 'app/shared/throttle-click.directive';
import { NgxBootstrapMultiselectModule } from 'ngx-bootstrap-multiselect';
import { ShareModule } from 'ngx-sharebuttons';
import { AppHeaderComponent } from './components/app-header.component'
import { TextCtaComponent } from './components/text-cta/text-cta.component';
import { FocusHoverDirective } from './directives/focus-hover.directive';
import { PipesModule } from 'app/modules/pipes/pipes.module';
import { BrandsShowcaseComponent } from './components/brands-showcase/brands-showcase.component';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    AppHeaderComponent,
    SpinnerComponent,
    ThrottleClickDirective,
    FocusHoverDirective,
    ShareComponent,
    BrandsShowcaseComponent,
  ],
  imports: [
    TextCtaComponent,
    RouterModule,
    FormsModule,
    CommonModule,
    IconModule,
    NgxBootstrapMultiselectModule,
    ShareModule,
    PipesModule,
    NgClickOutsideDirective,
  ],
  providers: [],
  exports: [
    FocusHoverDirective,
    CommonModule,
    FormsModule,
    AppHeaderComponent,
    SpinnerComponent,
    ThrottleClickDirective,
    NgxBootstrapMultiselectModule,
    IconModule,
    ShareComponent,
    TextCtaComponent,
    BrandsShowcaseComponent,
    NgClickOutsideDirective
  ]
})
export class NewSharedModule { }
