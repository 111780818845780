import { Action } from '@ngrx/store';

export enum UserLogoutActionTypes {
  USER_LOG_OUT = 'auth/logout/LOG_OUT'
}

export class UserLogoutAction implements Action {
  readonly type = UserLogoutActionTypes.USER_LOG_OUT;
}

export type UserLogoutActions = UserLogoutAction;
