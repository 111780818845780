import { ActionReducer } from '@ngrx/store';
import TemplateSubscription from 'app/models/template-subscription';
import { TemplateSubscriptionActions, TemplateSubscriptionActionTypes } from 'app/store/actions/template-subscription.actions';
import { remove, findIndex } from 'lodash-es';
import { UserLoginActionTypes, UserLoginActions } from 'app/store/actions/login.actions';
import { UserLogoutActions, UserLogoutActionTypes } from '../actions/logout.actions';

export const templateSubscriptionsReducer: ActionReducer<TemplateSubscription[]> = (state: TemplateSubscription[], action: TemplateSubscriptionActions | UserLoginActions | UserLogoutActions ) => {
  switch (action.type) {
    case UserLoginActionTypes.USER_LOGIN_SUCCESS:
      state = action.response.template_subscriptions;
      return state.slice(0);
    case UserLogoutActionTypes.USER_LOG_OUT:
      return [];
    case TemplateSubscriptionActionTypes.TEMPLATE_SUBSCRIPTIONS_FETCHED:
      state = action.templateSubscriptions;
      return state.slice(0);
    case TemplateSubscriptionActionTypes.TEMPLATE_SUBSCRIPTION_CREATED:
      state.push(action.templateSubscription)
      return state.slice(0);
    case TemplateSubscriptionActionTypes.TEMPLATE_SUBSCRIPTION_UPDATED:
      let index = findIndex(state, (templateSubscription: TemplateSubscription) => templateSubscription.id === action.templateSubscription.id);
      if(index < 0) return state.slice(0);
      state[index] = action.templateSubscription;
      return state.slice(0);
    case TemplateSubscriptionActionTypes.TEMPLATE_SUBSCRIPTION_DELETED:
      remove(state, (templateSubscription: TemplateSubscription) => templateSubscription.id === action.templateSubscription.id);
      return state.slice(0);
    default:
      return state;
  }
};

