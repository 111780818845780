import { Action } from '@ngrx/store';
import Note from 'app/models/note';
import { Step } from 'app/models/step';
// import Timeline from 'app/models/timeline';

export enum TimelineNoteActionTypes {
  TIMELINE_NOTE_CREATED = 'dashboard/notes/TIMELINE_NOTE_CREATED',
  TIMELINE_NOTE_DELETED = 'dashboard/notes/TIMELINE_NOTE_DELETED',

}

export enum StepNoteActionTypes {
  STEP_NOTE_CREATED = 'dashboard/notes/STEP_NOTE_CREATED',
  STEP_NOTE_DELETED = 'dashboard/notes/STEP_NOTE_DELETED'
}

export class TimelineNoteCreatedAction implements Action {
  readonly type = TimelineNoteActionTypes.TIMELINE_NOTE_CREATED;
  constructor(public timelineId: number, public note: Note) { }
}

export class TimelineNoteDeletedAction implements Action {
  readonly type = TimelineNoteActionTypes.TIMELINE_NOTE_DELETED;
  constructor(public timelineId: number, public note: Note) { }
}

export class StepNoteCreatedAction implements Action {
  readonly type = StepNoteActionTypes.STEP_NOTE_CREATED;
  constructor(public timelineId: number, public step: Step) { }
}

export class StepNoteDeletedAction implements Action {
  readonly type = StepNoteActionTypes.STEP_NOTE_DELETED;
  constructor(public timelineId: number, public step: Step) { }
}

export type TimelineNoteActions = TimelineNoteCreatedAction | TimelineNoteDeletedAction;
export type StepNoteActions = StepNoteCreatedAction | StepNoteDeletedAction;
