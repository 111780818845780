import { Store } from '@ngrx/store';
import { AppState } from 'app/store/states/app.state';
import { Timelineable as Timeline } from 'app/models/timeline';
import { values } from 'lodash-es';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { Step } from 'app/models/step';
import { currentUserIsInternalUser } from './user.selectors';

function getTimelineById(store: Store<AppState>, timelineId: number) {
  return store.select(state => state.dashboard.timelines.byId[timelineId]);
}

function getTemplate(store: Store<AppState>, timeline: Timeline) {
  return store.select(state => state.dashboard.timelines.templates)
    // .filter((templates: Timeline[], index: number) => {
    //   return templates[index].type === timeline.type;
    // })
    .pipe(
      map(templates => {
        return templates[0]//templates && templates[timeline.state] && templates[timeline.state][timeline.type];
      })
    )
}

function getSidebarTimelinesResults(store: Store<AppState>) {
  return store.select(state => state.dashboard.sidebarTimelinesResults);
}

function getSidebarTimelines(store: Store<AppState>) {
  return store.select(state => state.dashboard.sidebarTimelinesResults.sidebarTimelines);
}

function getSidebarTimelinesTotal(store: Store<AppState>) {
  return store.select(state => state.dashboard.sidebarTimelinesResults.total);
}

function shouldRefresh(store: Store<AppState>) {
  const TWO_SECONDS = 2 * 1000;
  return store.select(state => state.dashboard.lastRefresh)
    .pipe(
      map((lastRefreshDate: number = 0) =>
        new Date().getTime() - lastRefreshDate > TWO_SECONDS
      )
    )
}

function getAllTimelines(store: Store<AppState>): Observable<Timeline[]> {
  return store.select(state => state.dashboard.timelines.byId)
    .pipe(map(values))
}

function showSuppressedEmailsWarning(store: Store<AppState>, timelineId: number) : Observable<boolean> {
  return store.select(state => state.dashboard.timelines.byId[timelineId])
    .pipe(
      withLatestFrom(currentUserIsInternalUser(store)),
      map(([timeline, isInternalUser]) => {
        return (isInternalUser && timeline && timeline.suppress_emails);
      })
    )
}

function findStepByStepId(store: Store<AppState>, timelineId: number, stepId: number): Observable<Step> {
  return store.select(state => state.dashboard.timelines.byId[timelineId])
    .pipe(
      map(timeline => {
        for (let i: number; i < timeline.phases.length; i++) {
          const stepMatchingProvidedId =  timeline.phases[i].steps.find(step => step.id === stepId);

          if(stepMatchingProvidedId) return stepMatchingProvidedId;
        }

        return null;
      })
    )
}

// timelineFields can be on more than one step, this returns all steps that have the timelineField associated
function findStepsByTimelineFieldSlug(store: Store<AppState>, timelineId: number, timelineFieldSlug: string): Observable<Step[]> {
  return store.select(state => state.dashboard.timelines.byId[timelineId])
    .pipe(
      map(timeline => {
        const stepsUsingTimelineField: Step[] = [];

        timeline.phases.forEach(phase => {
          const stepMatchingProvidedId = phase.steps.find(step =>
            step.step_timeline_fields.some(stepTimelineField => stepTimelineField.timeline_field.slug === timelineFieldSlug)
          );

          if (stepMatchingProvidedId) stepsUsingTimelineField.push(stepMatchingProvidedId);
        })

        return stepsUsingTimelineField;
      })
    )
}

export {
  getTimelineById,
  getAllTimelines,
  getTemplate,
  getSidebarTimelines,
  getSidebarTimelinesTotal,
  getSidebarTimelinesResults,
  shouldRefresh,
  findStepByStepId,
  findStepsByTimelineFieldSlug,
  showSuppressedEmailsWarning,
}
