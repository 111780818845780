import { AppState, Store } from 'app/store/states/app.state';
import { map, filter } from 'rxjs/operators';
import { intersection, map as lodashMap, includes } from 'lodash-es';
import { Roles } from 'app/models/role';

function getCurrentUser(store: Store<AppState>) {
  return store.select('currentUser');
}

function getCurrentUserId(store: Store<AppState>) {
  return getCurrentUser(store).pipe(
    map(user => user && user.id)
  )
}

export function getCurrentUserRole(store: Store<AppState>) {
  return getCurrentUser(store).pipe(
    map(user => user && user.role)
  )
}

export function getCurrentUserTimeZone(store: Store<AppState>) {
  return store.select('currentUserSettings').pipe(
    map(currentUserSettings => currentUserSettings.time_zone))
  ;
}

export function getCurrentUserSettings(store: Store<AppState>) {
  return store.select('currentUserSettings');
}

function currentUserHasAbility(store: Store<AppState>, abilities: string[]){
  return getCurrentUser(store).pipe(
    filter(user => !!user),
    map(user => lodashMap(user.abilities, 'slug')),
    map((slugs: string[]) => !!intersection(slugs, abilities).length)
  )
}

function currentUserIsClient(store: Store<AppState>){
  return currentUserHasRole(store, [
    Roles.client
  ]);
}

function currentUserHasRole(store: Store<AppState>, roles: string[]){
  return getCurrentUser(store).pipe(
    filter(user => !!user),
    map(user => includes(roles, user.role.slug)),
  )
}

function currentUserIsAgentOrCoordinator(store: Store<AppState>) {
  return currentUserHasRole(store, [
    Roles.listingCoordinator,
    Roles.listingAgent,
    Roles.buyerCoordinator,
    Roles.buyerAgent,
    Roles.fullAgent,
    Roles.transactionManager,
  ]);
}

function currentUserIsBuyerUser(store: Store<AppState>){
  return currentUserHasRole(store, [
    Roles.transactionManager,
    Roles.buyerCoordinator,
    Roles.buyerAgent,
    Roles.fullAgent,
  ]);
}

function currentUserIsInternalUser(store: Store<AppState>){
  return currentUserHasRole(store, [
    Roles.transactionManager,
    Roles.listingCoordinator,
    Roles.listingAgent,
    Roles.buyerCoordinator,
    Roles.buyerAgent,
    Roles.corporate,
    Roles.mortgageAdvisor,
    Roles.mortgageProcessor,
    Roles.title,
    Roles.fullAgent,
  ]);
}

function currentUserIsAgent(store: Store<AppState>){
  return currentUserHasRole(store, [
    Roles.listingAgent,
    Roles.buyerAgent,
    Roles.fullAgent,
  ]);
}

function currentUserIsCoordinator(store: Store<AppState>){
  return currentUserHasRole(store, [
    Roles.transactionManager,
    Roles.listingCoordinator,
    Roles.buyerCoordinator,
  ]);
}

function currentUserIsSalesManager(store: Store<AppState>){
  return getCurrentUser(store).pipe(
    filter(user => !!user),
    map(user => user.isSalesManager)
  )
}

// function currentUserAnnounceKitToken(store: Store<AppState>){
//   return store.select('currentUserAnnounceKitToken');
// }

export {
  getCurrentUser,
  getCurrentUserId,
  currentUserIsAgentOrCoordinator,
  currentUserIsInternalUser,
  currentUserHasAbility,
  currentUserHasRole,
  currentUserIsBuyerUser,
  currentUserIsAgent,
  currentUserIsCoordinator,
  currentUserIsClient,
  currentUserIsSalesManager,
  // currentUserAnnounceKitToken,
}
