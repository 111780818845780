import { RouterReducerState, routerReducer } from '@ngrx/router-store';
import { Store, ActionReducer, MetaReducer } from '@ngrx/store';
import QuestionnaireState from 'app/store/states/questionnaire.state';
import TemplateSubscription from 'app/models/template-subscription';
import InstanceSubscription from 'app/models/instance-subscription';
import DashboardState, { DotloopState } from 'app/store/states/dashboard.state';
import { CurrentUser, User } from 'app/models/user';
import { localStorageSync } from 'ngrx-store-localstorage';
import { questionnaireReducer } from 'app/store/reducers/questionnaire.reducers'
import { dashboardReducer } from 'app/store/reducers/dashboard.reducers';
import { templateSubscriptionsReducer } from 'app/store/reducers/template-subscription.reducers';
import { instanceSubscriptionsReducer } from 'app/store/reducers/instance-subscription.reducers';
import { actionItemsReducer } from 'app/store/reducers/action-item.reducers';
import { apiReducer, LoadingState } from 'app/store/reducers/api.reducer';
import { currentUserReducer } from 'app/store/reducers/current-user.reducer';
import { installMessageDismissedReducer } from 'app/store/reducers/install-message-dimissed.reducers';
import { AllManageTimelinesPreferences } from 'app/store/states/preferences.state';
import { manageTimelinesPreferencesReducer } from 'app/store/reducers/preferences.reducer';
import { backgroundImageReducer } from 'app/store/reducers/background-image.reducer';
import { coverageAreaReducer } from 'app/store/reducers/coverage-area.reducers';
import { mlsesReducer } from 'app/store/reducers/mlses.reducers';
import { CoverageAreas } from 'app/services/coverage-area.service';
import { ROUTER_NAVIGATION } from '@ngrx/router-store';
import { createMetaReducer, EventsMap } from 'redux-beacon';
import GoogleAnalytics, { trackPageView } from '@redux-beacon/google-analytics';
import { ActionItem } from 'app/models/action-item';
import { Mls } from 'app/models/mls';
import { dotloopReducer } from '../reducers/dotloop.reducers';
import UserSettings from 'app/models/user-setttings';
import { currentUserSettingsReducer } from '../reducers/current-user-settings.reducers';
import { documentTypesReducer } from '../reducers/document-types.reducer';
import { DocumentType } from 'app/models/document';
import { identityReducer, IdentityState } from '../reducers/identity.reducer';
import { Announcement } from 'app/models/announcement';
import { announcementReducer } from '../reducers/announcement.reducer';
import { mapsReducer } from '../reducers/maps.reducer';

const eventsMap: EventsMap = {
  [ROUTER_NAVIGATION]: trackPageView(action => ({
    page: action.payload.routerState.url,
  })),
};

const analyticsMetaReducer = createMetaReducer(eventsMap, GoogleAnalytics);

class AppState {
  router: RouterReducerState;
  questionnaire: QuestionnaireState;
  dashboard: DashboardState;
  dotloop: DotloopState;
  templateSubscriptions: TemplateSubscription[] = [];
  instanceSubscriptions: InstanceSubscription[] = [];
  actionItems: ActionItem[] = [];
  coverageAreas: CoverageAreas;
  mlses: Mls[] = [];
  currentUser: CurrentUser;
  currentUserSettings: UserSettings;
  loading: LoadingState;
  installMessageDismissed: boolean = false;
  backgroundImage: string;
  documentTypes: DocumentType[] = [];
  preferences: AllManageTimelinesPreferences;
  identity: IdentityState;
  announcements: Announcement[]= [];
  googleMapsLoaded: boolean = false;
};

const reducers = {
  router: analyticsMetaReducer(routerReducer),
  questionnaire: questionnaireReducer,
  dashboard: dashboardReducer,
  templateSubscriptions: templateSubscriptionsReducer,
  instanceSubscriptions: instanceSubscriptionsReducer,
  dotloop: dotloopReducer,
  actionItems: actionItemsReducer,
  currentUser: currentUserReducer,
  currentUserSettings: currentUserSettingsReducer,
  loading: apiReducer,
  installMessageDismissed: installMessageDismissedReducer,
  preferences: manageTimelinesPreferencesReducer,
  backgroundImage: backgroundImageReducer,
  coverageAreas: coverageAreaReducer,
  mlses: mlsesReducer,
  documentTypes: documentTypesReducer,
  identity: identityReducer,
  announcements: announcementReducer,
  googleMapsLoaded: mapsReducer,  // change key name to reflect object if neeeded
};

function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {

  const localStorageStateKeys = [
    // 'questionnaire', // we never use questionnaire state in a new session, so no reason to save this
    'currentUser',
    'currentUserSettings',
    'templateSubscriptions',
    'instanceSubscriptions',
    'actionItems',
    'dashboard',
    'dotloop',
    'preferences',
    'coverageAreas',
    'installMessageDismissed',
    'announcements'
  ];

  return localStorageSync({ keys: localStorageStateKeys, rehydrate: true, removeOnUndefined: true })(reducer);
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

export { Store, AppState, metaReducers, reducers };
