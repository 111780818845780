import Office from 'app/models/office';
import { Policy } from './policy';
import Role from './role';
import Ability from './ability';
import { Contact } from './contact';
import { IMultiSelectOption } from 'ngx-bootstrap-multiselect';
import { DropdownTimelineSearchResult } from './timeline-search';
import { BasicUser } from './basic-user';

export class User implements BasicUser {
  id: number;
  first_name: string;
  last_name: string;
  email_address: string;
  group_email_address: string;
  phone_number: string;
  role: Role;
  deactivated: boolean;
  profile_url: string;
  pre_consult_packet_url: string;
  confirmed: boolean;
  authorized_dotloop: boolean;
  mls_id: string;
  zillow_review_url: string; // for signup
  nrds_id: string; // for signup
  created_at: Date;
  timelines?: DropdownTimelineSearchResult[];
  office?: Office;
  avatar_url?: string;

  constructor(user?: Partial<User>){
    if (user) Object.assign(this, user);
    this.timelines = user?.timelines || [];
  }
}

export interface CurrentUser extends User {
  abilities: Ability[];
  isSalesManager: boolean; // store currentUser only
}

export function isInvalidEmail(email: string) {
  return !isValidEmail(email);
}

export function isValidEmail(email: string) {
  return /\S+@\S+\.\S+/.test(email);
}

export function userReducer(user: BasicUser | Contact) {
  return { id: user.id, name: `${user.last_name}, ${user.first_name}` };
};

// WARNING: just role slug, no id! do not use for anything besides a placeholder
export function blankUserWithRole(roleSlug: string) {
  // we need blank users to have roles because we rely on it later for finding the correct user collection
  return new User({id: null, role: new Role({ slug: roleSlug })});
}

// move to more appropriate location if found
export function locateUserInDropdownOptions(userId: number, userOptions: IMultiSelectOption[]): number[] {
  const user = userOptions.find(userOption => userOption.id == userId);
  if (!user) {
    return [];
  }

  // if the user is not a manager return the user id only,
  const { parentId: dropdownParentId } = user;
  if (!dropdownParentId) {
    return [user.id];
  }

  const manager = userOptions.find(userOption => userOption.id === dropdownParentId);
  if (manager?.params.userId !== user.id) {
    return [user.id];
  }

  const nestedDropdownIds = userOptions.filter(userOption => userOption.parentId === dropdownParentId)
    .map(userOption => userOption.id);

  // return attribute user's sales managed users
  return [dropdownParentId, ...nestedDropdownIds];
}
