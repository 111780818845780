import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Brands } from 'app/models/brand';
import { ListableListingTimeline } from 'app/models/listing-timeline';
import { capitalize } from 'app/shared/utilities/formatters';
import { map, switchMap, tap } from 'rxjs/operators';
import { IdentityService } from './identity.service';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class HeadService {

  constructor(
    private title: Title,
    private meta: Meta,
    private urlService: UrlService,
    private identityService: IdentityService
  ) { }

  setTitle(subtitle?: string) {
    return this.identityService.getCurrentBranding().pipe(
      map(brand => {
        const title = brand === Brands.newfound ? 'Dash' : this.friendlyCasing(brand);
        if (!subtitle) return title;

        return title + ' | ' + subtitle;
      }),
      tap(fullTitle => { this.title.setTitle(fullTitle) }),
    );
  }

  // note: this is subscribed to in app component, but some pages might override this with a more specific title
  // (an our homes listing, a timeline page)
  calculateAndSetTitle() {
    return this.urlService.currentUrl().pipe(
      map(url => this.currentPage(url)),
      switchMap(subtitle => this.setTitle(subtitle))
    );
  }

  // set before fetching the timeline to see prerender improves our seo score
  setIntitalMetaTagsForPublicListing() {
    const url = this.urlService.fullUrl();
    this.meta.addTag({ property: "og:url", name: 'og:url', content: url });
  }


  // note the opengraph standard uses non standard property as an attribute. name attribute works fine everywhere except on linked in.
  // without property="og:image", linkedin uses a random image from the page.
  // being redundant does nto seem to cause harm
  setMetaTagsForPublicListing(listableListingTimeline: ListableListingTimeline) {
    const description = this.description(listableListingTimeline)

    this.meta.updateTag({ property: "og:title", name: 'og:title', content: `${listableListingTimeline.title({ includeCommas: true })} | Houwzer Realtors` });
    this.meta.addTag({ property: "og:url", name: 'og:url', content: listableListingTimeline.listing_url });
    this.meta.addTag({ property: "og:site_name", name: 'og:site_name', content: 'Houwzer Realtors' });
    if (listableListingTimeline.photos.length) {
      this.meta.updateTag({ property: "og:image", name: 'og:image', content: listableListingTimeline.photos[0].original_url });
      this.meta.updateTag({ property: "og:image:alt", name: 'og:image:alt', content: 'Featured photo from the listing at ' + listableListingTimeline.title({ includeCommas: true }) });
      this.meta.updateTag({ name: 'twitter:image', content: listableListingTimeline.photos[0].original_url });
      this.meta.updateTag({ name: 'twitter:image:alt', content: 'Featured photo from the listing at ' + listableListingTimeline.title({ includeCommas: true }) });
    }
    this.meta.updateTag({ name: 'twitter:title', content: `${listableListingTimeline.title({ includeCommas: true })} | Houwzer Realtors` });
    this.meta.updateTag({ property: "og:description", name: 'og:description', content: description });
    this.meta.updateTag({ name: 'twitter:description', content: description });
    this.meta.updateTag({ name: 'description', content: description });
  }

  setDefaultMetaTags() {
    this.meta.updateTag({ property: "og:title", name: 'og:title', content: 'Dash | Powered by Newfound' });
    this.meta.updateTag({ property: "og:type", name: 'og:type', content: 'website' });
    this.meta.updateTag({ name: 'twitter:title', content: 'Dash | Powered by Newfound' });
    this.meta.updateTag({ property: "og:description", name: 'og:description', content: 'Full Service Realty For Thousands less' });
    this.meta.updateTag({ name: 'twitter:description', content: 'Full Service Realty For Thousands less' });
    this.meta.updateTag({ name: 'description', content: 'Full Service Realty For Thousands less' });
    this.meta.updateTag({ property: "og:image:alt", name: 'og:image:alt', content: 'generic photo of real estate' });
    this.meta.updateTag({ name: 'twitter:image:alt', content: 'generic photo of real estate' });

    // this.meta.updateTag({ name: 'twitter:image', content: 'assets/product/background-web.jpg'});
    // this.meta.updateTaproperty:"og:image", ({ name: 'og:image', content: 'assets/product/background-web.jpg'});
  }

  private currentPage(url: string) {
    const splitUrl = this.splitUrl(url);
    const [primaryChildRoute, secondaryChildRoute] = splitUrl;

    switch (primaryChildRoute) {
      case 'dash':
        return this.friendlyCasing(secondaryChildRoute);
      case 'our-homes':
        return 'Our Homes';
      case 'questionnaire':
        return 'Questionnaire';
      case 'login':
      case 'logout':
      case 'forgot-password':
      case 'reset-password':
      case 'pre-consultation':
      case 'signup':
        return 'Login';
      default:
        return null; // do not assume sub title for now
    }
  }

  private friendlyCasing(urlSegment: string) {
    return urlSegment.split('-').map(item => capitalize(item)).join(' ');
  }

  private splitUrl(_url: string) {
    // at first implementation, we only care about _not_ inadvertently displaying query params
    const url = _url.slice(1) // remove leading slash
    const [path, queryParams] = url.split('?');
    return path.split('/');
  }

  private description(listableListingTimeline: ListableListingTimeline) {
    const description = listableListingTimeline.timelineFieldValue('approved-listing-description')
    if (!description) return 'Full Service Realty For Thousands less';

    return description.slice(0, 140).split(' ').slice(0, -1).join(' ').replace(',', '') + '...';
  }
}
