import { Action } from '@ngrx/store';

export enum BackgroundImageActionTypes {
  SHOW_HOUSE_BACKGROUND = 'dashboard/backgroundImage/SHOW_HOUSE_BACKGROUND',
}

export class ShowHouseBackgroundImageAction implements Action {
  readonly type = BackgroundImageActionTypes.SHOW_HOUSE_BACKGROUND;
  constructor() { }
}

export type BackgroundImageActions = ShowHouseBackgroundImageAction;
