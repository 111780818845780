import { QuestionnaireActions, QuestionnaireActionTypes } from 'app/store/actions/questionnaire.actions'

import QuestionnaireState from 'app/store/states/questionnaire.state';
import Question from 'app/models/question';

/*
  This is the reducer! It's very important.
  The main thing to remember is to NEVER EVER return
  the same `state` object that gets passed in if you've changed anything.
  If you want your change propagated to listeners you must return a `new` object.
*/
export function questionnaireReducer(state: QuestionnaireState = new QuestionnaireState(), action: QuestionnaireActions) {
  switch (action.type) {
    case QuestionnaireActionTypes.QUESTION_FETCH_COMPLETE:
      const groupedQuestions = action.questions
        .reduce(
          function(acc: any, q: Question) {
            const oldValue = (state.questions[q.name] || {} as Question).value;
            return Object.assign(acc, { [q.name]: { ...q, value: oldValue } });
          },
          {}
        );
      return { ...state, questions: groupedQuestions };
    case QuestionnaireActionTypes.QUESTION_OPTION_CHANGED:
      const question = { ...state.questions[action.name] };
      question.value = action.newValue;
      return {
        ...state,
        questions: {
          ...state.questions,
          [action.name]: question
        }
      };
    case QuestionnaireActionTypes.QUSER_ATTR_CHANGED:
      return {
        ...state,
        user: {
          ...state.user,
          [action.name]: action.newValue
        }
      };
    case QuestionnaireActionTypes.SELL_FUNNEL_INITIATED:
      return { ...state, currentFlow: 'sell' };
    case QuestionnaireActionTypes.BUY_FUNNEL_INITIATED:
      return { ...state, currentFlow: 'buy' };
    case QuestionnaireActionTypes.BOTH_FUNNEL_INITIATED:
      return { ...state, currentFlow: 'both' };
    case QuestionnaireActionTypes.MORTGAGE_FUNNEL_INITIATED:
      return { ...state, currentFlow: 'mortgage' };
    case QuestionnaireActionTypes.REFINANCE_FUNNEL_INITIATED:
      return { ...state, currentFlow: 'refinance' };
    case QuestionnaireActionTypes.INQUIRY_FUNNEL_INITIATED:
      return { ...state, currentFlow: 'inquiry' };
    case QuestionnaireActionTypes.PAGE_COMPLETED:
      return { ...state, completedPages: state.completedPages.concat(action.stepNumber) };
    case QuestionnaireActionTypes.SURVEY_COMPLETED:
      return new QuestionnaireState();
    case QuestionnaireActionTypes.SURVEY_RESTARTED:
      return new QuestionnaireState();
    case QuestionnaireActionTypes.REFERRING_TIMELINE:
      return { ...state, referringListingId: action.referringListingId };
    case QuestionnaireActionTypes.ADDRESS_COMPONENTS:
      return { ...state, addressComponents: action.placeAddressComponents };
    case QuestionnaireActionTypes.SELECTED_PLACE:
      return { ...state, selectedPlace: action.selectedPlace };
    case QuestionnaireActionTypes.SELECTED_COORDINATES:
      return { ...state, selectedLatitude: action.selectedLatitude, selectedLongitude: action.selectedLongitude };
    default:
      return state;
  }
}
