import { Pipe, PipeTransform } from '@angular/core';
import { Asset, Assets } from 'app/models/asset';
import { Brands } from 'app/models/brand';
import { IDENTITIES } from 'app/models/identity';
import { IdentityService } from 'app/services/identity.service';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'asset'
})
export class AssetPipe implements PipeTransform {
  constructor(private identityService: IdentityService) { }

  transform(asset: keyof Assets, config: { identity?: Brands, tone?: keyof Asset } = {}) {
    return this.identityService.getCurrentBranding().pipe(
      map(brand => {
        const identity = config.identity || brand;
        const tone = config.tone || 'dark';
        return IDENTITIES[identity].assets[asset][tone];
      }));
  }
}
