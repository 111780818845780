import { Action } from '@ngrx/store';

export enum UserLoginActionTypes {
  USER_LOGIN_SUCCESS = 'auth/login/SUCCESS',
  USER_LOGIN_FAILURE = 'auth/login/FAILURE',
}

export class UserLoginSuccessAction implements Action {
  readonly type = UserLoginActionTypes.USER_LOGIN_SUCCESS;
  constructor(public response: any) { }
}

export class UserLoginFailureAction implements Action {
  readonly type = UserLoginActionTypes.USER_LOGIN_FAILURE;
  constructor(public response: any) { }
}

export type UserLoginActions = UserLoginSuccessAction | UserLoginFailureAction;
