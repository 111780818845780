import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { isEmpty } from 'lodash-es';
import { DashNotificationsService } from 'app/services/dash-notifications.service';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  password = '';
  passwordConfirmation = '';
  hideSuccess = true;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private notifications: DashNotificationsService
  ) {}

  ngOnInit() {}

  ngOnDestroy() {}

  onSubmit() {

    if (this.password === this.passwordConfirmation) {
      const token = this.route.snapshot.queryParamMap.get('token');
      this.authService.resetPassword(this.password, token).subscribe(
        next => {
          this.notifications.success('Your password has been changed.');
          this.router.navigate(['login']);
        },
        err => {
          // tslint:disable-next-line:max-line-length
          this.notifications.error('We couldn\'t change your password. The reset link you used may have expired. Please contact your agent or transaction manager for more help.');
        }
      );
    } else {
      this.notifications.error('Password and Confirmation must match.');
    }
  }

  submitDisabled() {
    return isEmpty(this.password) || isEmpty(this.passwordConfirmation) || this.password !== this.passwordConfirmation;
  }

}
