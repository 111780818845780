import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { map } from 'rxjs/operators';
import { AppState, Store } from 'app/store/states/app.state';
import { currentUserIsInternalUser } from 'app/store/selectors/user.selectors';
import { combineLatest } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class InternalUserAuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private store: Store<AppState>
  ){};

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return currentUserIsInternalUser(this.store).pipe(
      map((isInternalUser) => {
        if (isInternalUser) {
          return true;
        } else {
          this.router.navigate([ 'dash']);
          return false;
        }
      })
    )
  }
}
