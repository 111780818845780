import { ActionReducer } from '@ngrx/store';
import { BackgroundImageActions, BackgroundImageActionTypes } from 'app/store/actions/background-image.actions';

export const backgroundImageReducer: ActionReducer<string> = (state: string = null, action: BackgroundImageActions) => {
  switch (action.type) {
    case BackgroundImageActionTypes.SHOW_HOUSE_BACKGROUND:
      return "url('https://s3.amazonaws.com/houwzer-mailer-assets/invitation-mailer/house-fade.png')";
    default:
      return state;
  }
};
