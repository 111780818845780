import { Component, HostBinding, Input, OnInit } from '@angular/core';

// if accessing element via viewchild:   @ViewChild('btn', { static: true, read: ElementRef }) btn: ElementRef;

@Component({
  standalone: true,
  selector: 'button[textCta], a[textCta], span[textCta] ',
  template: `
    <ng-content select="[flattenBefore]">
    </ng-content><span><ng-content></ng-content></span>
    <ng-content select="[flattenAfter]">
  `,
  styleUrls: ['./text-cta.component.scss']
})
export class TextCtaComponent implements OnInit {
  @Input() textCta?: 'light' | 'dark' | 'primary' | 'error' |'call-to-action' | '';
  @Input() underlineOnHover: boolean = false;
  @Input() bold: boolean = false;
  @Input() small: boolean = false;
  @HostBinding('class') hostClass: string;

  ngOnInit() {
    const boldClass = this.bold ? 'bold' : '';
    const underLineClass = this.underlineOnHover ? 'underline-on-hover' : '';
    this.hostClass = this.textCta + ' ' + boldClass + ' ' + underLineClass;
  }
}
