import { AllManageTimelinesPreferences } from 'app/store/states/preferences.state';
import { ActionReducer } from '@ngrx/store';
import { PreferenceActions, PreferenceActionTypes } from 'app/store/actions/preferences.actions';

// See note in corresponding actions file for information about action types
// These are split out into seperate reducers now even though they currently have the same
// schema and could use the same reducers as we anticipate preferences eventually varying
// between listings and buyers.

export const manageTimelinesPreferencesReducer: ActionReducer<AllManageTimelinesPreferences> = (
  state: AllManageTimelinesPreferences = {},
  action: PreferenceActions
) => {

  state.manageListingTimelinePreferences = state.manageListingTimelinePreferences || {};
  state.manageBuyerTimelinePreferences = state.manageBuyerTimelinePreferences || {};

  switch (action.type) {
    case PreferenceActionTypes.SELECTED_BUYER_PREFERENCES_CHANGED: {
      const manageBuyerTimelinePreferences = {
        ...state.manageBuyerTimelinePreferences,
        ...action.selectedPreferences
      }

      return {
        ...state,
       manageBuyerTimelinePreferences
      };
    }
    case PreferenceActionTypes.SELECTED_LISTING_PREFERENCES_CHANGED: {
      const  manageListingTimelinePreferences= {
        ...state.manageListingTimelinePreferences,
        ...action.selectedPreferences
      }

      return {
        ...state,
        manageListingTimelinePreferences
      };
    }
    case PreferenceActionTypes.TOGGLE_LISTING_PHASE_GROUPS_ACTION: {

      const listingPreferences = { ...state.manageListingTimelinePreferences }

      listingPreferences.showPhaseSteps[action.phaseSlug] = !listingPreferences.showPhaseSteps[action.phaseSlug];

      return  {
        ...state,
        ...{ manageListingTimelinePreferences: listingPreferences }
      };
    }
    case PreferenceActionTypes.TOGGLE_BUYER_PHASE_GROUPS_ACTION: {

      const buyerPreferences = { ...state.manageBuyerTimelinePreferences }

      buyerPreferences.showPhaseSteps[action.phaseSlug] = !buyerPreferences.showPhaseSteps[action.phaseSlug];

      return  {
        ...state,
        ...{ manageBuyerTimelinePreferences: buyerPreferences }
      };
    }
    default:
      return state;
  }
};

