
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UrlService } from 'app/services/url.service';
import Invitation from 'app/models/invitation';
import { InvitationsService } from 'app/services/invitation.service';
import { User } from 'app/models/user';
import { find } from 'lodash-es';

@Component({
  templateUrl: './pre-consultation-signup.component.html',
  styleUrls: ['./pre-consultation-signup.component.scss']
})
export class PreConsultationSignupComponent implements OnInit {

  token: string;
  invitation: Invitation;
  primaryAgentName: string;

  constructor(
    private route: ActivatedRoute,
    public urlService: UrlService,
    private invitationsService: InvitationsService
  ) {}

  ngOnInit(){

      this.token = this.route.snapshot.queryParamMap.get('token');;
      this.invitationsService.getInvitation(this.token).subscribe((invitation: Invitation) => {
        this.invitation = invitation

        let primaryAgent = find(invitation.timeline.agents, (user: User) => user.id === invitation.timeline.primary_agent_id);
        this.primaryAgentName = primaryAgent.first_name;
      })
  }
}
