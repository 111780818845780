import { format, utcToZonedTime } from 'date-fns-tz';

export function formatDate(date: string | Date, timezone: string) {
  if(!date) {
    return null;
  }

  const zonedDate = utcToZonedTime(new Date(date), timezone)
  return format(zonedDate, 'EEE LLLL d, yyyy', { timeZone: timezone });
}

export function formatTime(date: string | Date, timezone: string){
  if(!date) {
    return null;
  }

  const zonedDate = utcToZonedTime(new Date(date), timezone)
  return format(zonedDate, 'h:mm a z', { timeZone: timezone });
}

export function formatDateTime(date: string | Date, timeZone: string){
  if(!date) {
    return null;
  }

  return `${formatDate(date, timeZone)} at ${formatTime(date, timeZone)}`
}
