import { ActionReducer, Action } from '@ngrx/store';

import { ApiActions, ApiActionTypes } from 'app/store/actions/api.action';


interface LoadingTimeouts {
  [key: number]: boolean
}

export class LoadingState {
  showLoading: boolean = false
  isAnyRequestActive: boolean = false
  timeouts: LoadingTimeouts = {};
}

export const apiReducer: ActionReducer<LoadingState> = (state = new LoadingState, action: ApiActions ) => {

  if (action.type == ApiActionTypes.CLEAR_LOADING) {
    for (var key in state.timeouts) {
      if (state.timeouts.hasOwnProperty(key)) {
        if(state.timeouts[key] === true) {
          clearInterval(parseInt(key));
        }
      }
    }
    state.timeouts = {};
    state.showLoading = false
    state.isAnyRequestActive = false
    return { ...state };
  }

  if (!action || !action.timeoutId) return state;

  switch (action.type) {

    case ApiActionTypes.ADD_LOADING_TIMEOUT:
      state.timeouts[action.timeoutId] = false;
      state.isAnyRequestActive = findAnyActiveRequest(state);
      return { ...state };

    case ApiActionTypes.REMOVE_LOADING_TIMEOUT:
      clearTimeout(action.timeoutId);
      delete state.timeouts[action.timeoutId];
      state.showLoading = isLoading(state);
      state.isAnyRequestActive = findAnyActiveRequest(state);
      return { ...state };

    case ApiActionTypes.SHOW_LOADING:
      const timeout = state.timeouts.hasOwnProperty(action.timeoutId);
      if (timeout) {
        state.timeouts[action.timeoutId] = true
      } else {
        clearInterval(action.timeoutId);
      };
      state.showLoading = isLoading(state);
      state.isAnyRequestActive = findAnyActiveRequest(state);
      return { ...state };

    default:
      return state;
  }
};

const isLoading = (state: LoadingState) => {
  for (var key in state.timeouts) {
    if (state.timeouts.hasOwnProperty(key)) {
      if(state.timeouts[key] === true){
        return true;
      }
    }
  }

  return false
}

const findAnyActiveRequest = (state: LoadingState) => Object.keys(state.timeouts).length > 0;
