import { ActionReducer } from '@ngrx/store';
import { Timelines, SidebarTimelineLink, SidebarTimelinesResults } from 'app/store/states/dashboard.state';
import { Timelineable as Timeline } from 'app/models/timeline';
import { values, find as lodashFind, remove, findIndex } from 'lodash-es';

import { UserLoginActionTypes, UserLoginActions } from 'app/store/actions/login.actions';
import { UserLogoutActionTypes, UserLogoutActions } from 'app/store/actions/logout.actions';
import { TimelineActionTypes, TimelineActions } from 'app/store/actions/timeline.actions'
import { TimelineNoteActionTypes, TimelineNoteActions, StepNoteActionTypes, StepNoteActions } from 'app/store/actions/note.actions';
import Phase from 'app/models/phase';
import Note from 'app/models/note';
import { Step } from 'app/models/step';
import { NEGATIVELY_TERMINAL_STATUSES } from 'app/models/status';

export const timelinesReducer: ActionReducer<Timelines> = (state: Timelines = new Timelines(), action: TimelineActions | UserLoginActions | UserLogoutActions | TimelineNoteActions | StepNoteActions) => {
  switch (action.type) {
    case TimelineActionTypes.TIMELINES_FETCHED:
      return addTimelinesToState(action.timelines, state);
    case UserLogoutActionTypes.USER_LOG_OUT:
      return new Timelines();
    case TimelineActionTypes.TIMELINE_CREATED:
    case TimelineActionTypes.TIMELINE_UPDATED:
      return addTimelinesToState([action.timeline], state);
    case TimelineActionTypes.TIMELINE_DELETED:
      return removeTimelineFromState(action.id, state);
    // case TimelineActionTypes.TIMELINE_USER_ADDED:
    //   return addUserToTimeline(action.timelineId, action.user, state);
    // case TimelineActionTypes.TIMELINE_USER_REMOVED:
    //   return removeUserFromTimeline(action.timelineId, action.userId, state);
    case TimelineNoteActionTypes.TIMELINE_NOTE_CREATED:
      return addNoteToTimeline(action.timelineId, action.note, state);
    case TimelineNoteActionTypes.TIMELINE_NOTE_DELETED:
      return removeNoteFromTimeline(action.timelineId, action.note, state);
    case StepNoteActionTypes.STEP_NOTE_CREATED:
      return updateStepInState(action.timelineId, action.step, state)
    case StepNoteActionTypes.STEP_NOTE_DELETED:
      return updateStepInState(action.timelineId, action.step, state)
    case TimelineActionTypes.TIMELINE_PHASE_UPDATED:
      return updatePhaseInState(action.phase, state);
    case TimelineActionTypes.TIMELINE_TEMPLATES_FETCHED:
      return replaceTemplatesInState(action.templates, state);
    default:
      return state;
  }
};

export const sidebarTimelinesResultsReducer: ActionReducer<SidebarTimelinesResults> = (state: SidebarTimelinesResults = { sidebarTimelines: [], total: 0 }, action: TimelineActions | UserLoginActions | UserLogoutActions) => {
  switch (action.type) {
    case UserLoginActionTypes.USER_LOGIN_SUCCESS:
      return setClientViewSidebarTimelines(action.response.timelines);
    case UserLogoutActionTypes.USER_LOG_OUT:
      return { sidebarTimelines: [], total: 0 };
    case TimelineActionTypes.SIDEBAR_TIMELINES_FETCHED:
      return setSidebarTimelines(action.timelines, state);
    case TimelineActionTypes.SIDEBAR_TIMELINES_SEARCH_CLEARED:
      return { sidebarTimelines: [], total: 0 };
    case TimelineActionTypes.TIMELINE_UPDATED:
      return updateSidebarTimelineName(action.timeline, state);
    case TimelineActionTypes.TIMELINE_DELETED:
      return removeSidebarTimeline(action.id, state);
    default:
      return state;
  }
};

function setClientViewSidebarTimelines(timelines: Timeline[]) { // not correct, should be timesearchresultpresenter
  if (!timelines?.length) {
    return { sidebarTimelines: [], total: 0 };
  }

  const sortedClientTimelines = summarize(timelines).sort((a, b) => {
    const aTerminal = NEGATIVELY_TERMINAL_STATUSES.includes(a.status);
    const bTerminal = NEGATIVELY_TERMINAL_STATUSES.includes(b.status);

    if (aTerminal && !bTerminal) {
      return 1;
    }

    if (!aTerminal && bTerminal) {
      return -1;
    }

    return 0;
  })

  return { sidebarTimelines: sortedClientTimelines, total: timelines.length }
}

function updateWithCompletedStepAction(state: Timelines, id: number, complete: boolean) {
  for (const timeline of values(state.byId)) {
    for (const phase of (timeline.phases || [])) {
      for (const step of phase.steps) {
        if (step.step_action.id === id) {
          step.step_action.is_complete = complete;
          return { ...state };
        }
      }
    }
  }
  return state;
}

function addTimelinesToState(timelines: Timeline[], state: Timelines) {
  if (!timelines.length) return state;

  return timelines.reduce((newState: Timelines, timeline) => {
    newState.byId[timeline.id] = timeline;
    return newState;
  }, { ...state });
}

function removeTimelineFromState(id: number, state: Timelines) {
  state.byId[id] = undefined;
  return { ...state };
}

function summarize(timelines: Timeline[]): SidebarTimelineLink[] {
  return timelines.map((timeline: Timeline) => {

    const statusTimelineField = lodashFind(timeline.timeline_fields, timelineField => timelineField.slug === 'status');

    return {
      id: timeline.id,
      name: timeline.name,
      type: timeline.type,
      status: statusTimelineField ? statusTimelineField.value : null,
      created_at: new Date(timeline.created_at)
    }
  })
}

// function addUserToTimeline(timelineId: number, user: User, state: Timelines): Timelines {
//   const timeline = {...state.byId[timelineId]};
//   switch (user.role) {
//     case 'client':
//       timeline.clients.push(user);
//       break;
//     case 'agent':
//       timeline.agents.push(user);
//       break;
//     case 'coordinator':
//       timeline.coordinators.push(user);
//     break;
//   }
//   state.byId = {...state.byId, [timelineId]: timeline};
//   return {...state};
// }

// function removeUserFromTimeline(timelineId: number, userId: number, state: Timelines): Timelines {
//   const timeline = {...state.byId[timelineId]};
//   const userMustGo = (aUser: User) => aUser.id === userId;
//   remove(timeline.clients, userMustGo);
//   remove(timeline.agents, userMustGo);
//   remove(timeline.coordinators, userMustGo);
//   state.byId = {...state.byId, [timelineId]: timeline};
//   return {...state};
// }

function addNoteToTimeline(timelineId: number, note: Note, state: Timelines) {
  const stateTimeline = { ...state.byId[timelineId] };
  stateTimeline.notes.push(note);
  state.byId = { ...state.byId, [timelineId]: stateTimeline };

  return { ...state };
}

function removeNoteFromTimeline(timelineId: number, note: Note, state: Timelines) {
  const stateTimeline = { ...state.byId[timelineId] };
  remove(stateTimeline.notes, stateNote => stateNote.id === note.id);
  state.byId = { ...state.byId, [timelineId]: stateTimeline };

  return { ...state };
}


function replaceTemplatesInState(templates: Timeline[], state: Timelines) {
  state.templates = templates;
  return { ...state };
}

// function addTemplateToState(template: Timeline, state: Timelines): Timelines {
//   state.templates = state.templates || {};
//   state.templates[template.state] = state.templates[template.state] || {}

//   state.templates[template.state][template.type] = template;

//   return {...state};
// }

function updatePhaseInState(phase: Phase, state: Timelines): Timelines {
  const timelineToUpdate = state.byId[phase.timeline_id];
  if (!timelineToUpdate) { return state; }

  timelineToUpdate.phases.splice(phase.sequence_number - 1, 1, phase);
  const newState = { ...state };
  newState.byId[phase.timeline_id] = timelineToUpdate;
  return newState;
}

function updateStepInState(timelineId: number, step: Step, state: Timelines) {
  const stateTimeline = { ...state.byId[timelineId] };

  stateTimeline.phases = stateTimeline.phases.map(phase => { // creating a new array
    if (phase.id !== step.phase_id) {
      return phase; //if step not in this phase return as is
    }

    phase.steps = phase.steps.map(stateStep => { // once we find the correct phase, next find step
      if (stateStep.id !== step.id) {
        return stateStep; // if step not step not associated with return as is
      }

      return step; // otherwise we have found modified step, return step passed in arguments
    });

    return phase; // return new phase with new step
  });
  state.byId = { ...state.byId, [timelineId]: stateTimeline };

  return { ...state };
}

function setSidebarTimelines(timelines: Timeline[], existingSidebarTimelinesResults: SidebarTimelinesResults = { sidebarTimelines: [], total: 0 }): SidebarTimelinesResults {

  const visibleTimelines = summarize(timelines)
    .sort((a: SidebarTimelineLink, b: SidebarTimelineLink) => {
      return a.created_at > b.created_at ? -1 : 1;
    })
    .slice(0, 5);

  return {
    sidebarTimelines: visibleTimelines,
    total: timelines.length
  }
}

function updateSidebarTimelineName(timeline: Timeline, state: SidebarTimelinesResults) {

  const newState = {
    sidebarTimelines: Object.assign(state.sidebarTimelines, {}),
    total: state.sidebarTimelines.length
  };

  const index = findIndex(state.sidebarTimelines, link => link.id === timeline.id);
  if (index >= 0) {
    newState.sidebarTimelines.splice(index, 1, {
      id: timeline.id,
      name: timeline.name,
      type: timeline.type,
      created_at: new Date(timeline.created_at)
    });
  }

  return newState;
}

function removeSidebarTimeline(id: number, state: SidebarTimelinesResults) {

  const newState = {
    sidebarTimelines: Object.assign(state.sidebarTimelines, {}),
    total: state.sidebarTimelines.length
  };

  remove(newState.sidebarTimelines, (sidebarTimelineLink: SidebarTimelineLink) => {
    if(sidebarTimelineLink.id === id) {
      newState.total =- 1;
      return true
    } else {
      return false;
    }
  });

  return newState;
}
