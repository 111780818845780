import { ActionReducer } from '@ngrx/store';
import { CurrentUser, User } from 'app/models/user';

import { UserActionTypes, UserActions } from 'app/store/actions/user.actions';
import { UserLoginActionTypes, UserLoginActions } from 'app/store/actions/login.actions';
import { UserLogoutActionTypes, UserLogoutActions } from 'app/store/actions/logout.actions';

export const currentUserReducer: ActionReducer<User> = (state: CurrentUser, action: UserLoginActions | UserLogoutActions | UserActions) => {
  switch (action.type) {
    case UserLoginActionTypes.USER_LOGIN_SUCCESS:
      return userFromPayload(action.response);
    case UserLogoutActionTypes.USER_LOG_OUT:
      return null;
    case UserActionTypes.USER_UPDATED:
      return (action.user.id === state.id) ? action.user : state;
    default:
      return state;
  }

  // todo, is it cool to not check cookie here
};

function userFromPayload(payload: any): CurrentUser {
  return {
    ...payload.user,
    authToken: payload.token,
    isSalesManager: payload.is_sales_manager // TODO: remove if we make a sales_manager role
  };
}
