import { Component } from '@angular/core';
import { environment } from '../environments/environment';
import { IdentityService } from './services/identity.service';
import { HeadService } from './services/head.service';
import { TooltipConfig } from 'ngx-bootstrap/tooltip';


function getTooltipConfig(): TooltipConfig {
  return Object.assign(new TooltipConfig(), {
    delay: 200,
  });
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [{ provide: TooltipConfig, useFactory: getTooltipConfig }]
})
export class AppComponent {

  notificationsOptions = {
    showProgressBar: false,
    position: ['top', 'right'],
    clickToClose: true,
    lastOnBottom: false,
    timeOut: 2500,
    icons: {
      alert: '',
      info: '',
      warn: '',
      success: '',
      error: ''
    }
  }

  enableAnimations: boolean;

  constructor(
    private identityService: IdentityService,
    private headService: HeadService
  ) { }

  ngOnInit() {
    this.identityService.syncDomToTheme().subscribe();
    this.identityService.syncIdentity().subscribe();
    this.headService.calculateAndSetTitle().subscribe();

    this.enableAnimations = environment.enableAnimations;
  }
}
