export interface Brand {
  name: string,
  // brandName: string,
  supportEmail: string,
  successEmail: string,
}

export enum Brands {
  houwzer = 'houwzer',
  newfound = 'newfound',
  trelora = 'trelora',
}
