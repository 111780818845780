import { Component } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { DashNotificationsService } from 'app/services/dash-notifications.service';

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  username = '';
  hideSuccess = true;
  successMessage = 'Please check your email in the next few minutes for the link to reset your password. Make sure to check your spam folder as well, just in case!'

  constructor(private authService: AuthService, private notifications: DashNotificationsService) { }

  forgotPassword() {
    this.authService.forgotPassword(this.username).subscribe(
      (response: any) => {
        this.hideSuccess = false;
        if(response && response.message){
          this.successMessage = response.message;
        }
      },
      err => {
        // tslint:disable-next-line:max-line-length
        this.notifications.error('We were unable to reset your password. Please make sure that the email address you provided is the one associated with your Dash account.');
      }
    );
  }

}
