import { Notifier } from '@airbrake/browser';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AirbrakeService {

  private airbrake: Notifier;

  constructor() {
    if (environment.airbrakeProjectId > 0 && environment.airbrakeProjectKey.length) {
      this.airbrake = new Notifier({
        projectId: environment.airbrakeProjectId,
        projectKey: environment.airbrakeProjectKey
      });
    }
  }

  notify(error: any) {
    if (this.airbrake) {
      this.airbrake.notify(error);
      return;
    }

    console.error(error);
  }
}
