 <div *ngIf="this.invitation">
  <div class="row text-center">
    <div class="col-sm-8 offset-sm-2 col-md-6 offset-md-3">
      <h3 class="heading">Thanks!</h3>
      <hr>
      <p class="signup-copy mt-3">
        We'll forward this along to {{ primaryAgentName }} so they can review it ahead of your consultation appointment and further prepare.
      </p>
      <p class="signup-copy mt-3">
        In the meantime, you can sign in below to get a sneak peak of our transaction management application, <b>Dash</b>. If you decide to work with us, all of your important information, instructions, notifications and reminders will be available here for reference at any time.
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6 offset-md-3 pt-3">
      <signup-form [invitation]="this.invitation" [token]="this.token"></signup-form>
    </div>
  </div>
</div>
