import { Injectable } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { Timelineable as Timeline } from 'app/models/timeline';

import { getTemplate } from 'app/store/selectors/timeline.selectors';
import { TimelineTemplateFetchedAction, TimelineTemplatesFetchedAction } from 'app/store/actions/timeline.actions';

import { tap, switchMap } from 'rxjs/operators';

export interface CreateTimelineOptions {
  type?: 'seller' | 'buyer'
};

export interface TemplateParams {
  step_action_slug?: string;
  timeline_field_slug?: string;
}

export enum TemplateTypes {
  buyer = 'buyer',
  seller = 'seller'
}

@Injectable({
  providedIn: 'root'
})
export class TemplateTimelineService {

  constructor(
    private apiService: ApiService,
    private store: Store<any>
  ) { }

  getTemplates(type?: TemplateTypes): Observable<Timeline[]>{

    let params: any = {}
    if(type) params['type'] = type;

    return this.apiService.get<Timeline[]>('timelines/template_timelines', { params: params, showLoadingIndicator: false }).pipe(
      tap((timelineTemplates: Timeline[]) => {
        this.store.dispatch(new TimelineTemplatesFetchedAction(timelineTemplates))
      })
    );
  }

  getTemplateTimeline(timeline: Timeline): Observable<Timeline> {
    return getTemplate(this.store, timeline)
      .pipe(
        switchMap((template: Timeline) => {
          if (template) return of(template);

          return this.apiService
            .get<Timeline>('timelines/template', {
              params: {
                type: timeline.type,
                state: timeline.state
              },
              showLoadingIndicator: false
            })
            .pipe(
              tap(template => {
                this.store.dispatch(new TimelineTemplateFetchedAction(template))
              })
            )
        })
      )
  }

}
