import { Action } from '@ngrx/store';
import { ManageTimelinesPreferences } from '../states/preferences.state';

// NOTE: These were split into actions updating each individual preference seperately.
// removed for simplicity. if for some reason thats needed again, can find in the git history

export enum PreferenceActionTypes {
  SELECTED_BUYER_PREFERENCES_CHANGED = 'app/dashboard/preferences/SELECTED_BUYER_PREFERENCES_CHANGED',
  SELECTED_LISTING_PREFERENCES_CHANGED = 'app/dashboard/preferences/SELECTED_LISTING_PREFERENCES_CHANGED',
  TOGGLE_LISTING_PHASE_GROUPS_ACTION = 'app/dashboard/preferences/TOGGLE_LISTING_PHASE_GROUPS_ACTION',
  TOGGLE_BUYER_PHASE_GROUPS_ACTION = 'app/dashboard/preferences/TOGGLE_BUYER_PHASE_GROUPS_ACTION',
}

export class SelectedBuyerPreferencesChangedAction implements Action {
  readonly type = PreferenceActionTypes.SELECTED_BUYER_PREFERENCES_CHANGED;
  constructor(public selectedPreferences: ManageTimelinesPreferences) { }
}

export class SelectedListingPreferencesChangedAction implements Action {
  readonly type = PreferenceActionTypes.SELECTED_LISTING_PREFERENCES_CHANGED;
  constructor(public selectedPreferences: ManageTimelinesPreferences) { }
}

export class ToggleListingPhaseGroupsAction implements Action {
  readonly type = PreferenceActionTypes.TOGGLE_LISTING_PHASE_GROUPS_ACTION;
  constructor(public phaseSlug: string) { }
}

export class ToggleBuyerPhaseGroupsAction implements Action {
  readonly type = PreferenceActionTypes.TOGGLE_BUYER_PHASE_GROUPS_ACTION;
  constructor(public phaseSlug: string) { }
}

export type PreferenceActions =
  SelectedBuyerPreferencesChangedAction |
  SelectedListingPreferencesChangedAction |
  ToggleListingPhaseGroupsAction |
  ToggleBuyerPhaseGroupsAction
