
import { createReducer, on } from '@ngrx/store';
import { Announcement } from 'app/models/announcement';
import { announcementDismissed, announcementRemoved, announcementSet, announcementsReplaced } from '../actions/announcement.actions';

export const initialAnnouncementState: Announcement[] = [];

export const announcementReducer = createReducer(
  initialAnnouncementState,
  on(announcementsReplaced, (_, { announcements }) => [...announcements]), // we caculated this value taking state into account in service
  on(announcementSet, (state, { announcement }) => [...state.filter(({ slug }) => slug !== announcement.slug), announcement]),
  on(announcementRemoved, (state, { slug }) => [...state.filter(({ slug: slugInStore }) => slug !== slugInStore)]),
  on(announcementDismissed, (state, { slug }) => {
    return state.map(announcement => {
      if (announcement.slug !== slug) return announcement;

      return {
        ...announcement,
        show: false
      }
    })
  }),
);
