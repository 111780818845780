import { Action } from '@ngrx/store';
import { CoverageAreaData } from 'app/services/coverage-area.service';

export enum CoverageAreaActionTypes {
  COVERAGE_AREA_FETCHED = 'COVERAGE_AREA_FETCHED'
}

export class CoverageAreaFetchedAction implements Action {
  readonly type = CoverageAreaActionTypes.COVERAGE_AREA_FETCHED;
  constructor(public coverageAreaData: CoverageAreaData) { }
}