import { ActionReducer } from '@ngrx/store';
import InstanceSubscription from 'app/models/instance-subscription';
import { InstanceSubscriptionActions, InstanceSubscriptionActionTypes } from 'app/store/actions/instance-subscription.actions';
import { remove, sortBy, findIndex } from 'lodash-es';
import { UserLoginActionTypes, UserLoginActions } from 'app/store/actions/login.actions';
import { UserLogoutActions, UserLogoutActionTypes } from '../actions/logout.actions';

export const instanceSubscriptionsReducer: ActionReducer<InstanceSubscription[]> = (state: InstanceSubscription[], action: InstanceSubscriptionActions | UserLoginActions | UserLogoutActions) => {
  switch (action.type) {
    case UserLoginActionTypes.USER_LOGIN_SUCCESS:
      state = action.response.instance_subscriptions;
      return state.slice(0);
    case UserLogoutActionTypes.USER_LOG_OUT:
      return [];
    case InstanceSubscriptionActionTypes.INSTANCE_SUBSCRIPTIONS_FETCHED:
      state = action.instanceSubscriptions;
      state = sortBy(state, instanceSubscription => instanceSubscription.subscribable_name);
      return state.slice(0);
    case InstanceSubscriptionActionTypes.INSTANCE_SUBSCRIPTION_CREATED:
      state.push(action.instanceSubscription)
      state = sortBy(state, instanceSubscription => instanceSubscription.subscribable_name);
      return state.slice(0);
    case InstanceSubscriptionActionTypes.INSTANCE_SUBSCRIPTION_UPDATED:
      let index = findIndex(state, (instanceSubscription: InstanceSubscription) => instanceSubscription.id === action.instanceSubscription.id);
      if (index < 0) return state.slice(0);
      state[index] = action.instanceSubscription;
      return state.slice(0);
    case InstanceSubscriptionActionTypes.INSTANCE_SUBSCRIPTION_DELETED:
      remove(state, (instanceSubscription: InstanceSubscription) => instanceSubscription.id === action.instanceSubscription.id);
      state = sortBy(state, instanceSubscription => instanceSubscription.subscribable_name);
      return state.slice(0);
    default:
      return state;
  }
};

