import Phase from 'app/models/phase';
import { blankUserWithRole, User } from 'app/models/user';
import Note from 'app/models/note';
import Photo from 'app/models/photo';
import TimelineField, { TimelineFieldable } from 'app/models/timeline-field'
import TimelineContact from 'app/models/timeline-contact';
import { flatMap, find, cloneDeep } from 'lodash-es';
import { GroupableByPhase } from 'app/shared/components/phase-collections';
import Address from 'app/models/address';
import MlsTimeline from './mls-timeline';
import { Mls } from './mls';
import { TimelineBundleRebatable } from './timeline_bundle_rebate';
import { OpenHouse } from './open-house';
import { DropdownTimelineSearchResult, TimelineSearchResult } from './timeline-search';
import { BasicForm } from './basic-form';
import { TimelineModelTypes } from './timeline.types';
import { Roles } from './role';
import { Email } from './email';
import { Call } from './call';
import { Offer } from './offer';

export const conveyancingOrderedStatuses = [
  "Ordered by Houwzer",
  "Ordered by 3rd Party"
]

export interface Timelineable {
  id?: number;
  name: string;
  type: string;
  template_slug: string;
  template_name: string;
  office: string;
  state: string;
  current_phase?: Phase;
  address?: Address;
  group_phase?: Phase;
  dotloop_loop_url?: string;
  qualia_connect_url?: string;
  primary_coordinator_id?: number;
  primary_agent_id?: number;
  primary_client_id?: number;
  primary_mortgage_advisor_id?: number;
  primary_mortgage_processor_id?: number;
  primary_title_processor_id?: number;
  primary_listing_preparation_coordinator_id?: number;
  primary_mls?: Mls;
  referral_agent?: User;
  shared_agent_id?: number;
  test?: boolean;
  coordinators: User[];
  listing_preparation_coordinators: User[];
  agents: User[];
  clients: User[];
  mortgage_advisors: User[];
  mortgage_processors: User[];
  title_processors: User[];
  message_ids: number[];
  phases?: Phase[];
  notes?: Note[];
  notes_count?: number;
  emails?: Email[];
  email_count?: number;
  calls?: Call[];
  call_count?: number;
  photos?: Photo[];
  url: string;
  listing_url?: string;
  forms: BasicForm[];
  // offers?: Offer[];
  current_offer?: Offer;
  timeline_contacts?: TimelineContact[];
  timeline_fields?: TimelineFieldable[];
  mls_timelines?: MlsTimeline[];
  suppress_emails?: boolean;
  can: { suppress_emails: boolean };
  created_at?: string;
  updated_at?: string;
  bundle_rebate?: TimelineBundleRebatable;
  hubspot_deal_id?: number;
  hubspot_mortgage_deal_id?: number;
  open_houses?: OpenHouse[];
}

export default class Timeline implements GroupableByPhase {
  id?: number;
  name: string;
  type: string;
  template_slug: string;
  template_name: string;
  office: string;
  state: string;
  created_at?: string;
  updated_at?: string;
  current_phase?: Phase;
  address?: Address;
  test?: boolean;
  group_phase?: Phase;
  dotloop_loop_url?: string;
  qualia_connect_url?: string;
  primary_mls?: Mls;
  primary_coordinator_id?: number;
  primary_listing_preparation_coordinator_id?: number;
  primary_agent_id?: number;
  primary_client_id?: number;
  primary_mortgage_advisor_id?: number;
  primary_mortgage_processor_id?: number;
  referral_agent?: User;
  shared_agent_id?: number;
  coordinators: User[] = [];
  listing_preparation_coordinators: User[] = [];
  agents: User[] = [];
  clients: User[] = [];
  mortgage_advisors: User[];
  mortgage_processors: User[];
  title_processors: User[];
  message_ids: number[];
  phases: Phase[] = [];
  notes?: Note[];
  notes_count?: number;
  emails?: Email[];
  email_count?: number;
  photos?: Photo[];
  url: string;
  listing_url?: string;
  forms: BasicForm[];
  timeline_contacts?: TimelineContact[];
  timeline_fields: TimelineField[] | TimelineFieldable[] = [];
  mls_timelines?: MlsTimeline[] = [];
  suppress_emails?: boolean;
  can: { suppress_emails: boolean };
  bundle_rebate?: TimelineBundleRebatable;
  hubspot_deal_id?: number;
  hubspot_mortgage_deal_id?: number;
  open_houses?: OpenHouse[] = [];

  constructor(timelineable?: Timelineable) {
    if (timelineable) Object.assign(this, timelineable);
    this.coordinators = this.coordinators || [];
    this.agents = this.agents || [];
    this.clients = this.clients || [];
    this.mortgage_advisors = this.mortgage_advisors || [];
    this.mortgage_processors = this.mortgage_processors || [];
    this.title_processors = this.title_processors || [];
    this.address = this.address || {};
    this.type = this.type || null;
    this.name = this.name || null;
  }

  steps() {
    return flatMap(this.phases, phase => phase.steps);
  }

  timelineField(slug: string) {
    return find(this.timeline_fields, (timelineField: TimelineField) => {
      return timelineField.slug === slug;
    });
  }
}

export function primaryCoordinator(timeline: Timelineable) {
  return timeline.coordinators.find((coordinator: User) => {
    return coordinator.id === timeline.primary_coordinator_id;
  })
}

export function primaryAgent(timeline: Timelineable) {
  return timeline.agents.find((agent: User) => {
    return agent.id === timeline.primary_agent_id;
  })
}

export function primaryClient(timeline: Timelineable) {
  return timeline.clients.find((client: User) => {
    return client.id === timeline.primary_client_id;
  })
}

export function primaryMortgageAdvisor(timeline: Timelineable) {
  return timeline.mortgage_advisors.find((user: User) => {
    return user.id === timeline.primary_mortgage_advisor_id;
  })
}

export function primaryMortgageProcessor(timeline: Timelineable) {
  return timeline.mortgage_processors.find((user: User) => {
    return user.id === timeline.primary_mortgage_processor_id;
  })
}

export function primaryTitleProcessor(timeline: Timelineable) {
  return timeline.title_processors.find((user: User) => {
    return user.id === timeline.primary_title_processor_id;
  })
}

export function timelineUsers({
  agents,
  clients,
  coordinators,
  title_processors,
  mortgage_advisors,
  mortgage_processors,
  listing_preparation_coordinators,
}: Timelineable) {
  return [
    ...agents,
    ...clients,
    ...coordinators,
    ...title_processors,
    ...mortgage_advisors,
    ...mortgage_processors,
    ...listing_preparation_coordinators,
  ]
}

export function getTimelineContact(timeline: Timelineable, contactSlug: string) {
  const timelineContact = timeline.timeline_contacts.find(timelineContact => timelineContact.slug === contactSlug);

  if(timelineContact?.contact) {
    return timelineContact;
  }

  return null;
}

export function isSeller(timeline:  Timelineable | TimelineSearchResult | DropdownTimelineSearchResult){
  switch (timeline.type) {
    case TimelineModelTypes.Seller:
    case TimelineModelTypes.ProfessionalSeller:
      return true;
    case TimelineModelTypes.Buyer:
      return false;
    default:
      return false
  }
}

export function isBuyer(timeline: Timelineable | TimelineSearchResult | DropdownTimelineSearchResult) {
  return !isSeller(timeline);
}

export function forTimelineUsersForm(timeline: Timelineable) {
  const forForm = (users: User[], roleSlug: Roles) => {
    if (users.length) return users;
    return [blankUserWithRole(roleSlug)];
  }
  return cloneDeep({
    ...timeline,
    agents: forForm(timeline.agents, Roles.agent),
    clients: forForm(timeline.clients, Roles.client),
    coordinators: forForm(timeline.coordinators, Roles.transactionManager),
    title_processors: forForm(timeline.title_processors, Roles.title),
    mortgage_advisors: forForm(timeline.mortgage_advisors, Roles.mortgageAdvisor),
    mortgage_processors: forForm(timeline.mortgage_processors, Roles.mortgageProcessor),
  })
}


export function groupEmailAddress({ primary_agent_id, agents }: Timelineable) {
  return agents.find(agent => agent.id === primary_agent_id)?.group_email_address;
}
