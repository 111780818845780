import { Routes } from '@angular/router';
import { PageNotFoundComponent } from 'app/shared/components/page-not-found/page-not-found.component';

export const routes: Routes = [
  { path: 'questionnaire', loadChildren: () => import('./questionnaire/questionnaire.module').then(m => m.QuestionnaireModule) },
  { path: 'our-homes', loadChildren: () => import('./our-homes/our-homes.module').then(m => m.OurHomesModule) },
  { path: 'dash', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'dashboard', redirectTo: 'dash' },
  { path: '', redirectTo: '/dash/home', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent },
];
