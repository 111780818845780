import { Routes } from '@angular/router';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { SignupPageComponent } from './pages/signup-page/signup-page.component';
import { InvalidInvitationComponent } from './pages/invalid-invitation/invalid-invitation.component';
import { AcceptedInvitationComponent } from './pages/accepted-invitation/accepted-invitation.component';
import { ExpiredInvitationComponent } from './pages/expired-invitation/expired-invitation.component';

import { LoginGuard } from './login.guard';
import { PreConsultationSignupComponent } from './pages/signup-page/pre-consultation-signup.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { AuthLayoutComponent } from './auth-layout/auth-layout/auth-layout.component';

export const authenticationRoutes: Routes = [
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule), canActivate: [LoginGuard] },
  { path: '', component: AuthLayoutComponent, children: [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    { path: 'logout', component: LogoutComponent },
    { path: 'forgot-password', component: ForgotPasswordComponent },
    { path: 'reset-password', component: ResetPasswordComponent },
    { path: 'pre-consultation/signup', component: PreConsultationSignupComponent },
    { path: 'signup', component: SignupPageComponent },
    { path: 'signup/invalid_invitation', component: InvalidInvitationComponent },
    { path: 'signup/accepted_invitation', component: AcceptedInvitationComponent },
    { path: 'signup/expired_invitation', component: ExpiredInvitationComponent },
  ]}
];
