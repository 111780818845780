import { ActionReducer } from '@ngrx/store';
import UserSettings from 'app/models/user-setttings';
import { UserSettingsActionTypes, UserSettingsUpdatedAction } from '../actions/current-user-settings.actions';
import { UserLoginActionTypes, UserLoginSuccessAction } from '../actions/login.actions';
import { UserLogoutAction, UserLogoutActionTypes } from '../actions/logout.actions';

export const currentUserSettingsReducer: ActionReducer<UserSettings> = (state: UserSettings = null, action: UserLoginSuccessAction | UserSettingsUpdatedAction | UserLogoutAction ) => {
  switch (action.type) {
    case UserSettingsActionTypes.USER_SETTINGS_UPDATED:
      return action.userSettings;
    case UserLoginActionTypes.USER_LOGIN_SUCCESS:
      return action.response.user_settings;
    case UserLogoutActionTypes.USER_LOG_OUT:
      return {};
    default:
      return state;
  }
};
