import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HalvesPipe } from './pipes/halves.pipe';
import { ThirdsPipe } from './pipes/thirds.pipe';
import { DashDatePipe } from './pipes/dash-date.pipe';
import { DashDateTimePipe } from './pipes/dash-date-time.pipe';
import { LockedTimelineFieldPipe } from './pipes/locked-timeline-field.pipe';
import { FullNamePipe } from './pipes/full-name.pipe';
import { TimelineUrlPipe } from './pipes/timeline-url.pipe';
import { FormatPipe } from './pipes/format.pipe';
import { LinkWrapPipe } from './pipes/link-wrap.pipe';
import { CentsToCurrencyPipe } from './pipes/cents-to-currency.pipe';
import { EditUserUrlPipe } from './pipes/edit-user-url.pipe';
import { FormUrlPipe } from './pipes/form-url.pipe';
import { EditTimelineUrlPipe } from './pipes/edit-timeline-url.pipe';
import { MessageStatusPipe } from './pipes/message-status.pipe';
import { ResolverPipe } from './pipes/resolver.pipe';
import { UserWithRoleOnTimelinePipe } from './pipes/user-with-role-on-timeline.pipe';
import { DocumentTypePipe } from './pipes/document-type.pipe';
import { FormattedTimelineFieldPipe } from './pipes/timeline-field-format.pipe';
import { DashTimePipe } from './pipes/dash-time.pipe';
import { FormattedStepTimelineFieldPipe } from './pipes/step-timeline-field-format.pipe';
import { ActionItemUrlPipe } from './pipes/action-item-url.pipe';
import { HubspotUrlPipe } from './pipes/hubspot-url.pipe';
import { HubspotMortgageUrlPipe } from './pipes/hubspot-mortgage-url.pipe';
import { AssetPipe } from './pipes/asset.pipe';
import { BrandingPipe } from './pipes/branding.pipe';
import { WithHyperlinksPipe } from './pipes/with-hyperlinks.pipe';
import { GroupEmailAddressPipe } from './pipes/group-email-address.pipe';
import { CheckboxFormControlNamePipe } from './pipes/checkbox-form-control-name.pipe';
import { AddressPipe } from './pipes/address.pipe';
import { ContactNamePipe } from './pipes/contact-name.pipe';
import { OfferUrlPipe } from './pipes/offer-url.pipe';

@NgModule({
  declarations: [
    HalvesPipe,
    ThirdsPipe,
    FormatPipe,
    FullNamePipe,
    LinkWrapPipe,
    HubspotUrlPipe,
    HubspotMortgageUrlPipe,
    TimelineUrlPipe,
    DashTimePipe,
    DashDatePipe,
    DashDateTimePipe,
    CentsToCurrencyPipe,
    LockedTimelineFieldPipe,
    EditUserUrlPipe,
    FormUrlPipe,
    EditTimelineUrlPipe,
    MessageStatusPipe,
    ResolverPipe,
    UserWithRoleOnTimelinePipe,
    DocumentTypePipe,
    FormattedTimelineFieldPipe,
    FormattedStepTimelineFieldPipe,
    ActionItemUrlPipe,
    AssetPipe,
    BrandingPipe,
    WithHyperlinksPipe,
    GroupEmailAddressPipe,
    CheckboxFormControlNamePipe,
    AddressPipe,
    ContactNamePipe,
    OfferUrlPipe,
  ],
  providers: [
    DashTimePipe,
    DashDatePipe,
    DashDateTimePipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    HalvesPipe,
    ThirdsPipe,
    FormatPipe,
    FullNamePipe,
    LinkWrapPipe,
    HubspotUrlPipe,
    HubspotMortgageUrlPipe,
    TimelineUrlPipe,
    DashTimePipe,
    DashDatePipe,
    DashDateTimePipe,
    CentsToCurrencyPipe,
    LockedTimelineFieldPipe,
    EditUserUrlPipe,
    FormUrlPipe,
    EditTimelineUrlPipe,
    MessageStatusPipe,
    ResolverPipe,
    UserWithRoleOnTimelinePipe,
    DocumentTypePipe,
    FormattedTimelineFieldPipe,
    FormattedStepTimelineFieldPipe,
    ActionItemUrlPipe,
    AssetPipe,
    BrandingPipe,
    WithHyperlinksPipe,
    GroupEmailAddressPipe,
    CheckboxFormControlNamePipe,
    AddressPipe,
    ContactNamePipe,
    OfferUrlPipe,
  ]
})
export class PipesModule { }
