import { Action } from '@ngrx/store';

export enum InstallMessageDismissedActionTypes {
  DISMISSED = 'app/dashboard/install-message-dismissed/DISMISSED'
}

export class InstallMessageDismissedAction implements Action {
  readonly type = InstallMessageDismissedActionTypes.DISMISSED;
  constructor() { }
}

export type InstallMessageDismissedActions = InstallMessageDismissedAction;