import { Injectable } from '@angular/core';

import { NotificationsService, NotificationType } from 'angular2-notifications';

export interface DashNotificationOptions {
  forceClickToClose?: boolean;
  timeout?: number;
  header?: string;
}

@Injectable({
  providedIn: 'root'
})
export class DashNotificationsService {

  constructor(private notifications: NotificationsService) {}

  success(message: string, options?: DashNotificationOptions){
    if(!options) options = {};
    options.header = options.header || 'Success!';
    options.timeout = options.timeout || 2500;

    this.showNotification(message, NotificationType.Success, options, 'success');
  }

  error(message: string, options?: DashNotificationOptions){
    if(!options) options = {};
    options.header = options.header || 'Oops!';
    options.timeout = options.timeout || 5000;

    this.showNotification(message, NotificationType.Error, options, 'error');
  }

  info(message: string, options?: DashNotificationOptions){
    if(!options) options = {};
    options.header = options.header || 'Heads Up!';
    options.timeout = options.timeout || 2500;

    this.showNotification(message, NotificationType.Info, options, 'info');
  }

  private showNotification(message: string, type: NotificationType, options: DashNotificationOptions, icon: string) {

    let notificationOptions: any = {}

    if(options.forceClickToClose){
      notificationOptions.timeOut = 0;
    } else {
      notificationOptions.timeOut = options.timeout || 2500;
    }

    let html = `<div style="float:right"><img style="height: 10px" src="assets/icons/notifications_x.png"></img></div>`

    if(options.header){
      html = html.concat(`<div style="margin-bottom: 5px; font-weight: bold">${options.header}</div>`)
    }

    html = html.concat(`<div>${message}</div>`)

    this.notifications.html(html, type, notificationOptions, icon);
  }
}
