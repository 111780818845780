import { ActionReducer } from '@ngrx/store';
import { CoverageAreaActionTypes, CoverageAreaFetchedAction } from 'app/store/actions/coverage-area.actions';
import { RemoteCoverageAreaActionTypes, RemoteCoverageAreaFetchedAction } from 'app/store/actions/remote-coverage-area.actions';
import { CoverageAreas } from 'app/services/coverage-area.service';

export const coverageAreaReducer: ActionReducer<CoverageAreas> =
  (state: CoverageAreas = {}, action: (CoverageAreaFetchedAction | RemoteCoverageAreaFetchedAction)) => {
    switch (action.type) {
      case CoverageAreaActionTypes.COVERAGE_AREA_FETCHED:
        state.primary = action.coverageAreaData;
        return { ...state};
      case RemoteCoverageAreaActionTypes.REMOTE_COVERAGE_AREA_FETCHED:
        state.remote = action.remoteCoverageAreaData;
        return { ...state};
      default:
        return state;
    }
  }
