import { Sort, SortDirection } from "@angular/material/sort";
import { GenericNameSlugAttribute } from "./reporting-display-column";
import {TimelineSearchFilters} from './timeline-search'
import { BasicUser } from "./basic-user";

export const SavedReportTypes = {
  LISTING: 'listing',
  BUYER: 'buyer'
};


export interface SavedReport {
  id?: number,
  name: string,
  report_type: 'listing' | 'buyer';
  filters: TimelineSearchFilters,
  display_data: GenericNameSlugAttribute[];
  user: BasicUser,
  shared_users?: BasicUser[],
  created_at?: Date,
  updated_at?: Date,
  sort: Sort[],
}

export interface ApiSavedReport extends Omit<SavedReport, 'sort'> {
  sort_direction: SortDirection,
  sort_column_slug: string,
}

export interface SavedReportParams {
  name: string,
  report_type: 'listing' | 'buyer';
  filters: TimelineSearchFilters,
  display_data: GenericNameSlugAttribute[];
  sort: Sort[],
}

export interface ApiSavedReportParams extends Omit<SavedReportParams, 'sort'> {
  sort_direction: SortDirection,
  sort_column_slug: string,
}

