<header class="container-fluid">
  <div class="app-header row justify-content-between align-items-center">
  <a [href]="hostName"><img [src]="'icon' | asset | async" class="header-logo"></a>
  <nav>
    <ul class="navbar">
      <li class="nav-item"><a textCta class="nav-link align-self-center" [href]="hostName + '/contact'">Support</a></li>
      <li class="nav-item"><a textCta class="nav-link align-self-center" [routerLink]="['/login']">Login</a></li>
    </ul>
  </nav>
  </div>
</header>
