
import { createReducer, on } from '@ngrx/store';
import { googleMapsLoadStateUpdated } from '../actions/maps.actions';

export const initialGoogleMapsLoadedState = false;

// making this piece of state extremely simple for now. if we need more app state around maps it can be turned into an object.
export const mapsReducer = createReducer(
  initialGoogleMapsLoadedState,
  on(googleMapsLoadStateUpdated, (state, { isGoogleMapsLoaded }) => isGoogleMapsLoaded),
);
