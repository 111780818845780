  <div class="row text-center">
    <div class="col-md-6 offset-md-3">
      <h3 class="heading">Welcome Back!</h3>
      <hr>
      <p class="invitation-copy">
        It looks like this invitation has already been accepted! You can login <a textCta="light" [routerLink]="['/login']">here</a>
        or if you've forgotten your password you can reset it <a textCta="light" [routerLink]="['/forgot-password']">here</a>.
        If you have any questions, do not hesitate to reach out to us at <a textCta="light" href="mailto:{{ 'supportEmail' | branding | async }}">{{ 'supportEmail' | branding | async }}</a>
        or <a textCta="light" href="tel:215-607-8942">(215) 607-8942</a>
      </p>
    </div>
  </div>
