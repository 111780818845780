import { Directive, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

import { throttleTime } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

// pretty much a duplicate of debounce click, except doesnt allow another event to happen for the given
// time, instead of waiting for a period of time after the last click to send the event

@Directive({
  selector: '[throttleClick]'
})
export class ThrottleClickDirective implements OnInit {

  @Output() throttleClick = new EventEmitter();

  private clicks = new Subject();
  private subscription: Subscription;

  constructor() {}

  ngOnInit() {
    this.subscription = this.clicks
      .pipe(throttleTime(1001))
      .subscribe(e => this.throttleClick.emit(e));
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  @HostListener('click', ['$event'])
  clickEvent(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }
}
