import { ActionReducer } from '@ngrx/store';
import { InstallMessageDismissedActionTypes, InstallMessageDismissedActions } from 'app/store/actions/install-message-dismissed.actions';

export const installMessageDismissedReducer: ActionReducer<Boolean> = (state: Boolean, action: InstallMessageDismissedActions) => {
  switch (action.type) {
    case InstallMessageDismissedActionTypes.DISMISSED:
      return (state !== true) ? true : state;
    default:
      return state;
  }
};