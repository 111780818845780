import { Injectable } from '@angular/core';

import { AppState, Store } from 'app/store/states/app.state';

import { chain, forOwn, includes } from 'lodash-es';
import { ApiService } from './api.service';
import { CoverageAreaFetchedAction } from 'app/store/actions/coverage-area.actions';
import { RemoteCoverageAreaFetchedAction } from 'app/store/actions/remote-coverage-area.actions';

import { Observable, forkJoin } from 'rxjs';
import { catchError, map, tap, withLatestFrom } from 'rxjs/operators';
import Address from 'app/models/address';

export interface ZipCodeData {
  zipCode: string,
  state: string,
  county: string,
  inCoverageArea: boolean
}

export interface CoverageAreaData {
  [state: string]: {
    [county: string]: string[]
  }
}

export interface CoverageAreas {
  primary?: CoverageAreaData;
  remote?: CoverageAreaData;
}

export interface County {
  name: string;
  covered: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CoverageAreaService {

  constructor(private store: Store<AppState>, private api: ApiService) { }

  fetchCoverageAreas() {
    let coverageFetch = this.api.get('data/coverage_area', { showLoadingIndicator: false }).pipe(
      tap((coverageAreaData: CoverageAreaData) => this.store.dispatch(new CoverageAreaFetchedAction(coverageAreaData)))
    );
    let remoteCoverageFetch = this.api.get('data/remote_coverage_area', { showLoadingIndicator: false }).pipe(
      tap((remoteCoverageAreaData: CoverageAreaData) => this.store.dispatch(new RemoteCoverageAreaFetchedAction(remoteCoverageAreaData)))
    );
    return forkJoin([coverageFetch, remoteCoverageFetch]);
  }

  fetchCoveredNeighborhoods() {
    return this.api.get('data/neighborhoods');
  }


  // handled by geocode

  // getCoveredZipCodes() {
  //   return this.store.select('coverageAreas').pipe(
  //     map((coverageAreas: CoverageAreas) => {

  //       let coveredZipCodes: any[] = []

  //       forOwn(coverageAreas.primary, (counties) => {
  //         forOwn(counties, (zipCodes) => {
  //           coveredZipCodes = coveredZipCodes.concat(zipCodes);
  //         })
  //       })

  //       return coveredZipCodes;
  //     })
  //   )
  // }

  // getRemoteCoveredZipCodes() {
  //   return this.store.select('coverageAreas').pipe(
  //     map((coverageAreas: CoverageAreas) => {

  //       let coveredZipCodes: any[] = []

  //       forOwn(coverageAreas.remote, (counties) => {
  //         forOwn(counties, (zipCodes) => {
  //           coveredZipCodes = coveredZipCodes.concat(zipCodes);
  //         })
  //       })

  //       return coveredZipCodes;
  //     })
  //   )
  // }

  // getCoveredCounties(state: Observable<string>) {
  //   return this.getAllCounties(state).pipe(
  //     map((counties: County[]) => counties
  //       .filter(county => county.covered)
  //       .map(county => county.name)
  //       .sort()
  //     )
  //   )
  // }

  // getUncoveredCounties(state: Observable<string>) {
  //   return this.getAllCounties(state).pipe(
  //     map((counties: County[]) => counties
  //       .filter(county => !county.covered)
  //       .map(county => county.name)
  //       .sort()
  //     )
  //   )
  // }

  // getAllCounties(state: Observable<string>) {
  //   return state.pipe(
  //     withLatestFrom(this.getCoverageAreas()),
  //     map(([state, coverageAreas]) => {
  //       try {
  //         const coverageAreaData = coverageAreas.primary;
  //         return Object.keys(coverageAreaData[state])
  //           .sort()
  //           .map(county => {
  //             const zipCodes = coverageAreaData[state][county];
  //             return {
  //               name: county,
  //               covered: !!zipCodes.length
  //             }
  //           })
  //       } catch (e) {
  //         // could be one of serveral things including no state, or no coverage area for that state
  //         return []
  //       }
  //     }),
  //   )
  // }

  // isOutsideCoverageArea(address: Address) {
  //   return this.getCoverageAreas().pipe(
  //     map(coverageAreas => {
  //       return address.state && address.county && address.zip_code && !includes(coverageAreas.primary[address.state][address.county], address.zip_code)
  //     })
  //   )
  // }

  // invertedCoverageData(state: Observable<string>) {
  //   return state.pipe(
  //     withLatestFrom(this.getCoverageAreas()),
  //     map(([state, { primary: coverageAreaData }]) => {
  //       const counties = coverageAreaData[state];
  //       if(!counties) return {};

  //       return Object.entries(counties).reduce((acc: { [key: string]: string[] }, [county, zipCodes]) => {
  //         zipCodes.forEach(zip => {
  //           if (!acc[zip]) {
  //             acc[zip] = [];
  //           }

  //           acc[zip].push(county);
  //         });

  //         return acc;
  //       }, {});
  //     })
  //   )
  // }

  // private getCoverageAreas() {
  //   return this.store.select('coverageAreas');
  // }
}
