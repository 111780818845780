import { ActionReducer } from '@ngrx/store';
import { UserLoginActionTypes, UserLoginSuccessAction } from '../actions/login.actions';
import { UserLogoutActions, UserLogoutActionTypes } from '../actions/logout.actions';

export const dotloopReducer: ActionReducer<string> = (state: string = null, action: UserLoginSuccessAction | UserLogoutActions ) => {
  switch (action.type) {
    case UserLoginActionTypes.USER_LOGIN_SUCCESS:
      return action.response.dotloop_state_token;
    case UserLogoutActionTypes.USER_LOG_OUT:
      return null;
    default:
      return state;
  }
};
