import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AirbrakeService } from 'app/services/airbrake.service';
import { ApiService } from 'app/services/api.service';
import { AuthService } from 'app/services/auth.service';
import { UrlService } from 'app/services/url.service';
import { CookieService } from 'ngx-cookie-service';
import { of, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

/**
  This component exists to give the app an unprotected route to redirect to
  before invalidating the token an deleting the current user.
  The we have to wait for the current User to be set to null before redirecting to /login,
  because the LoginGuard stops logged in users from visiting /login.
  Setting the user to null updates currentUser store subscriptions across the app, and there is not an easy
  way to get in between the currentUser set to null and the subscriptions in order to redirect before we get errors.
  Thus, when a user clicks the logout button, they will be redirected here to this empty component,
  logged out, then redirected to the login page.
**/

@Component({
  selector: 'logout',
  template: '',
})
export class LogoutComponent implements OnInit {

  constructor(
    private apiService: ApiService,
    private urlService: UrlService,
    private airbrakeService: AirbrakeService,
    private router: Router,
    private route: ActivatedRoute,
    private cookieService: CookieService

  ) { }

  private logoutSubscription: Subscription

  ngOnInit(): void {
    if(!this.cookieService.check('authToken')){
      // handles visits to this page by an alraedy logged out user
      this.endSessionAndRedirectToLogin();
      return;
    }

    this.logoutSubscription = this.apiService
      .delete(`/tokens/${this.cookieService.get('authToken')}`).pipe(
        tap(async () => await this.endSessionAndRedirectToLogin()),
        catchError((err) => {
          this.airbrakeService.notify({ error: `LOGOUT ERROR: ${err}` });
          return of(null);
        })
      ).subscribe();
  }

  ngOnDestroy() {
    this.logoutSubscription?.unsubscribe();
  }

  private async endSessionAndRedirectToLogin() {
    this.apiService.endSession();
    const queryParams = { returnUrl: this.route.snapshot.queryParams.returnUrl }; // when undefined, query param not included, which is what we want
    // now that we are logged out we can redirect to the login page and pass the LoginGuard check
    // replaceUrl removes this page from the browser history stack, so hitting the back button in the browser wont bring you here
    const routingExecuted = await this.router.navigate(this.urlService.loginUrl(), { replaceUrl: true, queryParams });

    if (!routingExecuted) {
      this.airbrakeService.notify('LOGOUT ERROR: A user was not redirected back to the login page after logging out');
    }
  }
}
