import { Action } from '@ngrx/store';
import InstanceSubscription from 'app/models/instance-subscription';

export enum InstanceSubscriptionActionTypes {
  INSTANCE_SUBSCRIPTIONS_FETCHED = 'instance_subscriptions/FETCHED',
  INSTANCE_SUBSCRIPTION_CREATED = 'instance_subscriptions/CREATED',
  INSTANCE_SUBSCRIPTION_DELETED = 'instance_subscriptions/DELETED',
  INSTANCE_SUBSCRIPTION_UPDATED = 'instance_subscriptions/UPDATED'
}

export class InstanceSubscriptionsFetchedAction implements Action {
  readonly type = InstanceSubscriptionActionTypes.INSTANCE_SUBSCRIPTIONS_FETCHED;
  constructor(public instanceSubscriptions: InstanceSubscription[]) { }
};

export class InstanceSubscriptionCreatedAction implements Action {
  readonly type = InstanceSubscriptionActionTypes.INSTANCE_SUBSCRIPTION_CREATED;
  constructor(public instanceSubscription: InstanceSubscription) { }
};

export class InstanceSubscriptionDeletedAction implements Action {
  readonly type = InstanceSubscriptionActionTypes.INSTANCE_SUBSCRIPTION_DELETED;
  constructor(public instanceSubscription: InstanceSubscription) { }
};

export class InstanceSubscriptionUpdatedAction implements Action {
  readonly type = InstanceSubscriptionActionTypes.INSTANCE_SUBSCRIPTION_UPDATED;
  constructor(public instanceSubscription: InstanceSubscription) { }
};

export type InstanceSubscriptionActions = InstanceSubscriptionsFetchedAction | InstanceSubscriptionCreatedAction | InstanceSubscriptionDeletedAction | InstanceSubscriptionUpdatedAction;
