import { Timelineable as Timeline } from 'app/models/timeline';
import { User } from 'app/models/user';

export interface SidebarTimelinesResults {
  sidebarTimelines: SidebarTimelineLink[],
  total: number
}

export default class DashboardState {
  timelines: Timelines = new Timelines();
  sidebarTimelinesResults: SidebarTimelinesResults = { sidebarTimelines: [], total: 0 };
  lastRefresh: number;
  users: Users = new Users();
  backgroundImage: string = null;
};

export interface ById<T> {
  [id: number]: T;
};

export class Timelines {
  byId: ById<Timeline> = {};
  templates?: Timeline[] = [];
}

export interface SidebarTimelineLink {
  id: number;
  name: string;
  type: string;
  status?: string;
  created_at: Date;
}

export class Users {
  searchResults: User[];
}

export interface DotloopState {
  state_token: string;
}
