import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, ActivatedRoute } from '@angular/router';

import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';

// import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';;

@Injectable({ providedIn: 'root' })
export class LoginGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router
  ) {};

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    return this.authService.isLoggedIn().pipe(
      map((isLoggedIn) => {
        if(isLoggedIn) {
          const url = route.queryParams.returnUrl ? route.queryParams.returnUrl : '/dash';
          this.router.navigate([url]);
          return false;
        } else {
          return true;
        }
      })
    )
  }
}
