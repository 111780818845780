import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Options } from 'angular2-notifications';

@Component({
  selector: 'app-notifications',
  templateUrl: './app-notifications.component.html',
  styleUrls: ['./app-notifications.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class AppNotificationsComponent {


  @Input('enableAnimations') enableAnimations: boolean;

  notificationsOptions: Options = {
    showProgressBar: false,
    position: ['top', 'right'],
    clickToClose: true,
    lastOnBottom: false,
    timeOut: 2500,
    icons: {
      alert: '',
      info: '',
      warn: '',
      success: '',
      error: ''
    }
  }

}
