import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';

import { RouterModule } from '@angular/router';
import { NewSharedModule } from 'app/new_shared/new-shared.module';

import { SignupPageComponent } from './pages/signup-page/signup-page.component';
import { SignupFormComponent } from './pages/signup-page/signup-form.component';
import { InvalidInvitationComponent } from './pages/invalid-invitation/invalid-invitation.component';
import { AcceptedInvitationComponent } from './pages/accepted-invitation/accepted-invitation.component';

import { AuthGuard } from './auth.guard';
import { LoginGuard } from './login.guard';
import { DotloopAuthGuard } from './dotloop-auth.guard';
import { PreConsultationSignupComponent } from './pages/signup-page/pre-consultation-signup.component';
import { InternalUserAuthGuard } from './internal-user-auth.guard';
import { LogoutComponent } from './pages/logout/logout.component';
import { PipesModule } from 'app/modules/pipes/pipes.module';
import { AuthLayoutModule } from './auth-layout/auth-layout.module';
import { ExpiredInvitationComponent } from './pages/expired-invitation/expired-invitation.component';

@NgModule({
  imports: [
    RouterModule,
    FormsModule,
    NewSharedModule,
    PipesModule,
    CommonModule,
    AuthLayoutModule,
  ],
  providers: [
    AuthGuard,
    DotloopAuthGuard,
    InternalUserAuthGuard,
    LoginGuard
  ],
  declarations: [
    ExpiredInvitationComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    InvalidInvitationComponent,
    AcceptedInvitationComponent,
    PreConsultationSignupComponent,
    SignupPageComponent,
    SignupFormComponent,
    LogoutComponent,
  ],
  exports: []
})
export class AuthModule { }
