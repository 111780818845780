<div *ngIf="this.invitation">

  <div *ngIf="!this.isConfirmedUser()" class="row text-center">
    <div class="col-sm-12">
      <h2 class="heading">Create Your Account</h2>
      <hr>
    </div>
  </div>
  <div *ngIf="this.isConfirmedUser()" class="row text-center">
    <div class="col-12 col-md-8 offset-md-2">
      <h3 class="heading">Welcome!</h3>
      <hr>
      <p class="signup-copy">
        Hi {{this.user.first_name }}, we've found your account! Before we get started, please take a
        moment to set a password and confirm your information is both correct and complete. If you have any questions
        do not hesitate to reach out to us at <a textCta="light" href="mailto:{{ 'supportEmail' | branding | async }}">{{ 'supportEmail' | branding | async }}</a>
        or <a textCta="light" href="tel:215-607-8942">(215) 607-8942</a>
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6 offset-md-3 pt-3">
      <signup-form [invitation]="this.invitation" [token]="this.token"></signup-form>
    </div>
  </div>
</div>
