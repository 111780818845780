import { Injectable } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import Invitation from 'app/models/invitation';
import { Observable } from 'rxjs';

export interface PaginatedResponse {
  total: number;
  data: any[];
}

@Injectable({ providedIn: 'root' })
export class InvitationsService {

  constructor(private apiService: ApiService) { }

  getInvitation(token: string): Observable<Invitation>{
    return this.apiService.get<Invitation>(`/invitations/${token}`);
  }

  getInvitations(searchValue: string, pageIndex: number, pageSize: number){
    let params: any = {
      search: searchValue,
      page_index: pageIndex,
      page_size: pageSize
    };
    return this.apiService.get<PaginatedResponse>('invitations', { params });
  }

  revoke(invitation: Invitation){
    return this.apiService.delete(`invitations/${invitation.id}`);
  }

  renew(emailAddress: string){
    return this.apiService.post<Invitation>('invitations/renew', { email_address: emailAddress });
  }
}
