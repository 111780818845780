  <div class="row text-center">
    <div class="col-sm-12">
      <h2 class="heading">{{hideSuccess ? 'Reset Your Password' : 'Success!'}}</h2>
    </div>
  </div>
  <div class="row mt-2">
    <div *ngIf="hideSuccess; else success" class="col col-sm-8 col-md-6 offset-md-3">
      <form>
        <div class="form-group">
          <label for="username">Email Address</label>
          <input #usernameInput="ngModel"
            class="form-control" required
            name="username" pattern="\S+@\S+\.\S+"
            type="email" [(ngModel)]="username">
        </div>
        <div class="row justify-content-center">
          <button class="btn btn-primary" (throttleClick)="forgotPassword()" [disabled]="usernameInput.invalid">Submit</button>
        </div>
      </form>
    </div>
    <ng-template #success>
      <div class="col-md-6 offset-md-3 text-center">
        <div class="forgot-password-copy">{{ successMessage }}</div>
        <br>
        <a textCta="light" textCta="light" [routerLink]="['/login']" class="mt-2">Return to the Log In Page</a>
      </div>
    </ng-template>
  </div>
