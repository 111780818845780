import { ErrorHandler, Injectable } from '@angular/core';
import { Notifier } from '@airbrake/browser';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class AirbrakeErrorHandler implements ErrorHandler {

  airbrake: Notifier;

  constructor() {
    if (environment.airbrakeProjectId > 0 && environment.airbrakeProjectKey.length) {
      this.airbrake = new Notifier({
        projectId: environment.airbrakeProjectId,
        projectKey: environment.airbrakeProjectKey
      });
    }
  }

  handleError(error: any): void {

    // Any error from the API is likely not something we want to report as a frontend issue and is largely
    // considered noise from the frontend error monitoring perspective (5xx errors will be reported to error
    // monitoring by the backend. 4xx errors are not really errors and should just be handled in the frontend
    // via some sort of user message). Ideally, we're specifying an error callback when subscribing to the api
    // service and show the error message in that callback. If we don't specify and error callback, the error keeps
    // traveling down the chain until it ends up here. In that case we've apparently choosen or forgotten to handle
    // the error, but we still don't want to report it to aibrake. We no longer have access to the HTTP code here, only
    // the error itself, so the best and only way we currently have to ignore API errors at this late point is to check
    // for the houwzer_error_code returned from all HouwzerError's.
    if (!error?.houwzer_error_code && this.airbrake) {
      this.airbrake.notify(error);
    } else {
      console.error(error);
    }
  }
}
