import { Action } from '@ngrx/store';

export enum ApiActionTypes {
  ADD_LOADING_TIMEOUT = '/app/store/actions/api/ADD_LOADING_TIMEOUT',
  REMOVE_LOADING_TIMEOUT = '/app/store/actions/api/REMOVE_LOADING_TIMEOUT',
  SHOW_LOADING = '/app/store/actions/api/SHOW_LOADING_TIMEOUT',
  CLEAR_LOADING = '/app/store/actions/api/CLEAR_LOADING_TIMEOUTS',
  HIDE_LOADING = '/app/store/actions/api/HIDE_LOADING_TIMEOUT'
}

export class AddLoadingTimeoutAction implements Action {
  readonly type = ApiActionTypes.ADD_LOADING_TIMEOUT;
  constructor(public timeoutId: number) { }
}

export class ShowLoadingTimeoutAction implements Action {
  readonly type = ApiActionTypes.SHOW_LOADING;
  constructor(public timeoutId: number) { }
}

export class RemoveLoadingTimeoutAction implements Action {
  readonly type = ApiActionTypes.REMOVE_LOADING_TIMEOUT;
  constructor(public timeoutId: number) { }
}

export class ClearLoadingTimeoutAction implements Action {
  readonly type = ApiActionTypes.CLEAR_LOADING;
}


export type ApiActions = AddLoadingTimeoutAction | ShowLoadingTimeoutAction | RemoveLoadingTimeoutAction | ClearLoadingTimeoutAction;

