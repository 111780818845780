export const TIMELINE_BUYER = 'buyer';
export const TIMELINE_SELLER = 'seller';

export type TimelineType = 'buyer' | 'seller';

export const TimelineTypePrefix: { [k in TimelineType]: string; } = {
  'seller': 'Listing - ',
  'buyer': 'Home Search - '
};

export enum TimelineModelTypes {
  Buyer = 'Timelines::BuyerTimeline',
  Seller = 'Timelines::SellerTimeline',
  ProfessionalSeller = 'Timelines::ProfessionalSellerTimeline',
}
