import { User } from 'app/models/user';
import { combineReducers, Action, ActionReducer } from '@ngrx/store';
import { UserManagementActions, UserManagementActionTypes } from 'app/store/actions/user-management.actions';
import { UserActions, UserActionTypes } from 'app/store/actions/user.actions';
import { filter, map } from 'lodash-es';
import { UserLogoutAction, UserLogoutActionTypes } from '../actions/logout.actions';

const searchResultsReducer: ActionReducer<User[]> = function (state: User[] = [], action: UserActions | UserManagementActions | UserLogoutAction) {
  switch (action.type) {
    case UserManagementActionTypes.SEARCH_RESULTS_FETCHED:
      return action.results;
    case UserActionTypes.USER_UPDATED:
    case UserActionTypes.USER_FETCHED:
      return map(state, (user: User) => {
        if(user.id === action.user.id) return action.user;
        return user;
      })
    case UserActionTypes.USER_DELETED:
      return filter(state, (user: User) => {
        return user.id !== action.user.id
      });
    case UserLogoutActionTypes.USER_LOG_OUT:
      return [];
    default:
      return state;
  }
};

export const usersReducer = combineReducers({
  searchResults: searchResultsReducer,
});
