import { FullName } from 'app/models/contactable';
import { UrlSegment } from '@angular/router';

export function formatMissingFields(errors: string[]): string {
  let formattedErrors = errors.map(e => `- ${e}`).join('<br>');
  return "Please fill out the missing fields:<br>" + formattedErrors;
}

// takes iso8601 _date_, not a datetime.
// creates a time aware date object at midnight local time
export function appendTimeData(dateString: string): Date {
  const [year, month, day] = dateString.split('-')
  return new Date(parseInt(year), parseInt(month) - 1, parseInt(day), 0, 0, 0, 0)
}

export function normalizeStringForSorting(value: string) {
  return value.trim().toLowerCase();
}

export function typecastToBoolean(value: string){
  return String(value).toLowerCase() === 'true';
}

export function fullName(userInfo: FullName) {
  return `${userInfo.first_name} ${userInfo.last_name}`;
}

export function contactName(contactInfo: FullName) {
  if (contactInfo.company) {
    return `${contactInfo.company} (${fullName(contactInfo)})`;
  }

  return fullName(contactInfo);
}

export function questionnairePage(segments: UrlSegment[]) {
  // TODO strengthen: move somewhere else?
  const page = segments.find(
    urlSegment => /\d{1,}/.test(urlSegment.path)
  ).path;

  return parseInt(page);
}

export function intersection<T>(arrayOne: T[], arrayTwo: T[]) {
  return arrayOne.filter(selectedPhase => {
    return arrayTwo.includes(selectedPhase)
  });
}

export function capitalize(str: string) : string {
  return str.split(' ').map(word => word.slice(0, 1).toUpperCase().concat(word.slice(1))).join(' ');
}

export function numberParamArray(ids: null | string | string[])  {
  if (!ids) {
    return null;
  }

  return Array.isArray(ids) ? ids.map(Number) : [Number(ids)];
}

export function stringParamArray(items: null | string | string[])  {
  if (!items) {
    return null;
  }

  return Array.isArray(items) ? items : [items];
}


export function percentage(numerator: number, denominator: number) {
  const percentage = ((numerator / denominator) * 100);
  // if (typeof percentage !== 'number') return '0';

  return parseFloat(percentage.toFixed(4)).toString();
}
