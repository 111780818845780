// friendly_name is for use externally where we want to avoid jargony language while name is more appropriate for internal usage

// TODO: The name and definitely the slug need to match what's defined up on the API and we should really be pulling these values
// dynamically from the there. the slugs here match our status :symbols and are used for timeline search filtering

const listingStatuses = [
  { slug: 'transferred_to_agent', name: 'Transferred to Agent', friendly_name: 'Agent Introduction' },
  { slug: 'agent_connected', name: 'Agent Connected', friendly_name: 'Connected with Agent' },
  { slug: 'rescheduling', name: 'Rescheduling', friendly_name: 'Rescheduling' },
  { slug: 'awaiting_contract', name: 'Awaiting Contract', friendly_name: 'Awaiting Contract' },
  { slug: 'listing_agreement', name: 'Listing Agreement', friendly_name: 'Listing Agreement' },
  { slug: 'coming_soon', name: 'Coming Soon', friendly_name: 'Coming Soon' },
  { slug: 'active', name: 'Active', friendly_name: 'For Sale' },
  { slug: 'active_under_contract', name: 'Active Under Contract', friendly_name: 'Active Under Contract' },
  { slug: 'pending', name: 'Pending', friendly_name: 'Sale Pending' },
  { slug: 'closed', name: 'Closed', friendly_name: 'Sold' },
  { slug: 'expired', name: 'Expired', friendly_name: 'Expired' },
  { slug: 'withdrawn', name: 'Withdrawn', friendly_name: 'Withdrawn' },
  { slug: 'nurture', name: 'Nurture', friendly_name: 'Nurture' },
  { slug: 'lost', name: 'Lost', friendly_name: 'Lost' },
  { slug: 'unqualified', name: 'Unqualified', friendly_name: 'Unqualified' },
  { slug: 'temporarily_off_market', name: 'Temporarily Off Market', friendly_name: 'Temporarily Off Market' },
]

const buyerStatuses = [
  { slug: 'transferred_to_agent', name: 'Transferred to Agent', friendly_name: 'Agent Introduction' },
  { slug: 'agent_connected', name: 'Agent Connected', friendly_name: 'Connected with Agent' },
  { slug: 'rescheduling', name: 'Rescheduling', friendly_name: 'Rescheduling' },
  { slug: 'active_search', name: 'Active Search', friendly_name: 'Active Search' },
  { slug: 'pending', name: 'Pending', friendly_name: 'Pending' },
  { slug: 'post_settlement', name: 'Post-Settlement', friendly_name: 'Post-Settlement' },
  { slug: 'nurture', name: 'Nurture', friendly_name: 'Nurture' },
  { slug: 'unqualified', name: 'Unqualified', friendly_name: 'Unqualified' },
  { slug: 'lost', name: 'Lost', friendly_name: 'Lost' },
]

export interface Status {
  slug: string;
  name: string;
  disabled?: boolean;
}

export const NEGATIVELY_TERMINAL_STATUSES = [
  'Lost',
  'Expired',
  'Unqualified',
  'Withdrawn',
]

export const CLIENT_ACTIVE_STATUSES = [
  'Transferred to Agent',
  'Agent Connected',
  'Rescheduling',
  'Awaiting Contract',
  'Listing Agreement',
  'Coming Soon',
  'Active',
  'Active Under Contract',
  'Temporarily Off Market',
  'Transferred to Agent',
  'Agent Connected',
  'Rescheduling',
  'Active Search',
  'Nurture',
]

const mapStatusToDisplayStatus = (status: any) => {
  return { slug: status.slug, name: status.name, disabled: false };
}

export const internalListingStatuses = listingStatuses.map(status => mapStatusToDisplayStatus(status))

export const externalListingStatuses = listingStatuses.filter(
  listingStatus => ['coming_soon', 'active', 'active_under_contract', 'pending', 'closed'].includes(listingStatus.slug)
).map(status => mapStatusToDisplayStatus(status))

export const internalBuyerStatuses = buyerStatuses.map(status => mapStatusToDisplayStatus(status))
