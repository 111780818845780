<form #signupForm="ngForm" class="reset-password-form" (submit)="submit()">

  <div class="row">
    <div class="form-group col-6">
      <label for="first_name" class="form-control-label">First Name</label>
      <input
        type="text"
        name="first_name"
        class="form-control"
        #firstNameInput="ngModel"
        required
        [(ngModel)]="user.first_name"
        [class.is-invalid]="firstNameInput.touched && firstNameInput.invalid"
        >
    </div>
    <div class="form-group col-6">
      <label for="last_name" class="form-control-label">Last Name</label>
      <input
        type="text"
        name="last_name"
        class="form-control"
        #lastNameInput="ngModel"
        required [(ngModel)]="user.last_name"
        [class.is-invalid]="lastNameInput.touched && lastNameInput.invalid"
      >
    </div>
  </div>

  <div class="form-group">
    <label class="form-control-label" for="email_address">Email Address</label>
    <input type="email" name="email_address"
      [(ngModel)]="user.email_address"
      #emailInput="ngModel"
      [class.is-invalid]="emailInput.dirty && emailInput.invalid"
      class="form-control" required pattern="\S+@\S+\.\S+">
  </div>

  <div class="form-group">
    <label for="phone_number" class="form-control-label">Phone Number</label>
    <input type="text" class="form-control" name="phone" [(ngModel)]="user.phone_number">
  </div>

  <!--
  <div *ngIf="this.isInternalUser">
    <div class="form-group">
      <label for="gender" class="form-control-label">Gender</label>
      <select [required]="this.isInternalUser" name="role" class="form-control nocorners" [(ngModel)]="user.gender">
        <option value=""></option>
        <option value="male">Male</option>
        <option value="female">Female</option>
      </select>
    </div>
  </div>
  -->

  <div *ngIf="this.isAgent">
    <div class="form-group">
      <label for="mls_id" class="form-control-label">MLS ID</label>
      <input type="text" class="form-control" name="mls_id" [(ngModel)]="user.mls_id">
    </div>
    <div class="form-group">
      <label for="nrds_id" class="form-control-label">NRDS ID</label>
      <input type="text" class="form-control" name="nrds_id" [(ngModel)]="user.nrds_id">
    </div>
    <div class="form-group">
      <label for="zillow_review_url" class="form-control-label">Zillow Review Url</label>
      <input [required]="this.isInternalUser" type="text" class="form-control" name="zillow_review_url" [(ngModel)]="user.zillow_review_url">
    </div>
  </div>

  <div class="form-group">
    <label for="password" class="form-control-label">{{ this.user.id ? 'Password' : 'New Password' }}</label>
    <input
      #passwordInput="ngModel"
      autocomplete="new-password"
      required
      type="password"
      class="form-control"
      [class.is-invalid]="passwordInput.touched && passwordInput.invalid"
      [(ngModel)]="password"
      name="password"
    >
  </div>

  <div *ngIf="!this.isConfirmedUser()" class="form-group">
    <label for="password" class="form-control-label">Confirm New Password</label>
    <input
      #confirmationInput="ngModel"
      required
      type="password"
      class="form-control"
      [class.is-invalid]="passwordConfirmation.length > 0 && passwordConfirmation !== password"
      [(ngModel)]="passwordConfirmation"
      autocomplete="new-password"
      name="password"
    >
    <div [hidden]="passwordConfirmation.length === 0 || password === passwordConfirmation" class="form-control-feedback">Password and Confirmation Must Match</div>
  </div>

  <div class="row justify-content-center">
    <input class="btn btn-primary" type="submit" value="Let's Go" [disabled]="submitDisabled()">
  </div>

</form>
