import { User } from 'app/models/user';
import { Timelineable } from 'app/models/timeline';
import Phase from 'app/models/phase';

export enum TimelineActionTypes {
  DROPDOWN_TIMELINES_FETCHED = 'dashboard/timelines/dropdown/FETCHED',
  TIMELINES_FETCHED = 'dashboard/timelines/FETCHED',
  TIMELINE_CREATED = 'dashboard/timelines/CREATED',
  TIMELINE_UPDATED = 'dashboard/timelines/UPDATED',
  TIMELINE_DELETED = 'dashboard/timelines/DELETED',
  TIMELINE_USER_REMOVED = 'dashboard/timelines/user/REMOVED',
  TIMELINE_USER_ADDED = 'dashboard/timelines/user/ADDED',
  TIMELINE_TEMPLATES_FETCHED = 'dashboard/timelines/templates/FETCHED',
  TIMELINE_TEMPLATE_ADDED = 'dashboard/timelines/template/FETCHED',
  TIMELINE_PHASE_UPDATED = 'dashboard/timelines/phase/UPDATED',
  SIDEBAR_TIMELINES_FETCHED = 'dashboard/timelines/sidebar/FETCHED',
  SIDEBAR_TIMELINES_SEARCH_CLEARED = 'dashboard/timelines/sidebar/CLEARED',
  TIMELINE_VIEWED = 'dashboard/timelines/VIEWED',
  SORT_SIDEBAR_TIMELINES = 'dashboard/timelines/sidebar/SORT'
}

export class TimelineViewedAction {
  readonly type = TimelineActionTypes.TIMELINE_VIEWED;
  constructor(public timelineId: number) { }
};

export class SidebarTimelinesFetchedAction {
  readonly type = TimelineActionTypes.SIDEBAR_TIMELINES_FETCHED;
  constructor(public timelines: Timelineable[]) { }
};

export class SidebarTimelinesSearchClearedAction {
  readonly type = TimelineActionTypes.SIDEBAR_TIMELINES_SEARCH_CLEARED;
  constructor() { }
};

export class TimelinesFetchedAction {
  readonly type = TimelineActionTypes.TIMELINES_FETCHED;
  constructor(public timelines: Timelineable[]) { }
};

export class TimelineCreatedAction {
  readonly type = TimelineActionTypes.TIMELINE_CREATED;
  constructor(public timeline: Timelineable) { }
}

export class TimelineUpdatedAction {
  readonly type = TimelineActionTypes.TIMELINE_UPDATED;
  constructor(public timeline: Timelineable) { }
}

export class TimelineDeletedAction {
  readonly type = TimelineActionTypes.TIMELINE_DELETED;
  constructor(public id: number) { }
}

export class TimelineUserAddedAction {
  readonly type = TimelineActionTypes.TIMELINE_USER_ADDED;
  constructor(public timelineId: number, public user: User) { }
}

export class TimelineUserRemovedAction {
  readonly type = TimelineActionTypes.TIMELINE_USER_REMOVED;
  constructor(public timelineId: number, public userId: number) { }
}

export class TimelineTemplateFetchedAction {
  readonly type = TimelineActionTypes.TIMELINE_TEMPLATE_ADDED;
  constructor(public template: Timelineable) { }
}

export class TimelineTemplatesFetchedAction {
  readonly type = TimelineActionTypes.TIMELINE_TEMPLATES_FETCHED;
  constructor(public templates: Timelineable[]) { }
}

export class TimelinePhaseUpdatedAction {
  readonly type = TimelineActionTypes.TIMELINE_PHASE_UPDATED;
  constructor(public phase: Phase) { }
}

export type TimelineActions = TimelineViewedAction | SidebarTimelinesFetchedAction | TimelinesFetchedAction | TimelineCreatedAction | TimelineUpdatedAction | TimelineDeletedAction | TimelineUserAddedAction | TimelineUserRemovedAction | TimelineTemplateFetchedAction | TimelineTemplatesFetchedAction | TimelinePhaseUpdatedAction | SidebarTimelinesSearchClearedAction;
