export default class Role {
  id: number;
  name: string;
  slug: string; // TODO make Roles

  constructor(role?: Partial<Role>){
    if (role) Object.assign(this, role);
  }
}

export enum Roles {
  corporate = 'corporate',
  agent = 'full_agent',
  buyerAgent = 'buyer_agent',
  buyerCoordinator = 'buyer_coordinator',
  fullAgent = 'full_agent',
  listingAgent = 'listing_agent',
  listingCoordinator = 'listing_coordinator',
  mortgageAdvisor = 'mortgage_advisor',
  mortgageProcessor = 'mortgage_processor',
  transactionManager = 'transaction_manager',
  client = 'client',
  title = 'title',
}


export enum SellerTimelineRoles {
  listingAgent = Roles.listingAgent,
  fullAgent = Roles.fullAgent,
  listingCoordinator = Roles.listingCoordinator,
  transactionManager = Roles.transactionManager,
  client = Roles.client,
  title = Roles.title,
}

export enum BuyerTimelineRoles {
  client = Roles.client,
  title = Roles.title,
  buyerAgent = Roles.buyerAgent,
  fullAgent = Roles.fullAgent,
  buyerCoordinator = Roles.buyerCoordinator,
  mortgageAdvisor = Roles.mortgageAdvisor,
  mortgageProcessor = Roles.mortgageProcessor,
  transactionManager = Roles.transactionManager,
}
